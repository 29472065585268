const numberStyle = {
  background: "lightgreen",
  padding: "10px",
  border: "solid",
  borderRadius: "10px",
};
const numberGreenStyle = {
  background: "lightgreen",
  padding: "10px",
  border: "solid",
  borderRadius: "10px",
};
const numberGrayStyle = {
  background: "lightgray",
  padding: "10px",
  border: "solid",
  borderRadius: "10px",
};

const paperStyle = {
  backgroundColor: "white",
  margin: "10px",
  padding: "10px",
};

export { numberStyle, paperStyle, numberGreenStyle, numberGrayStyle };
