import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MyMobileApp from "./components/MyMobileApp";
import PunchTool from "./components/PunchTool";

console.error = () => { };
console.warn = () => { };
const App = () => {
  return (
    <React.Fragment>
      <BrowserView>
        {/* <Router>
          <Routes>
            <Route path="/" element={<AdultPunchTool />} />
            <Route path="/student" element={<StudentPunchTool />} />
            <Route path="/*" element={<AdultPunchTool />} />
          </Routes>
        </Router> */}
        <PunchTool />
      </BrowserView>

      <MobileView>
        <MyMobileApp />
      </MobileView>
    </React.Fragment>
  );
};

export default App;
