import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  FormGroup
} from "@mui/material";
import "../App.css";
import * as d3 from "d3";

import Plot from "./Plot.js";
import BoxPlot from "./BoxPlot";
import EditableTable from "./EditableTable";
import { getData, fieldType, readFileData } from "../helper";
import {
  ArrowBack,
  ArrowForward,
  Dataset,
  UploadFile,
} from "@mui/icons-material";

import { BoxOptions } from "./BrowserPlot";
import Paretochart from "./Paretochart";
import ScatterPlot from "./Scatterplot";
import TimeSeriesChart from "./TimeSeriesChart";

const MyMobileApp = () => {
  const [rowData, setRowData] = useState(getData());
  const [data, setData] = useState(getData());
  const [categoryField, setCategoryField] = useState("__NO__CATEGORY__");
  const [dataField, setDataField] = useState("DeliveryTime");
  const [targetMean, setTargetMean] = useState(30);
  const [categoryFields, setCategoryFields] = useState([]);
  const [xAxisLabel, setXAxisLabel] = useState(dataField);
  const [yAxisLabel, setYAxisLabel] = useState(categoryField);
  const [dataFields, setDataFields] = useState([]);
  const [lsl, setLSL] = useState(15);
  const [usl, setUSL] = useState(40);
  const [title, setTitle] = useState("Title");

  const [classicDesign, setClassicDesign] = useState(false);
  const [includeMarkers, setIncludeMarkers] = useState(false);

  const [outputBoxPlot, setOutputBoxPlot] = useState(false);
  const [ParetoChartBoxPlot, setParetoChartBoxPlot] = useState(false);
  const [ScatterChartBoxPlot, setScatterChartBoxPlot] = useState(false);
  const [TimeSeriesChartBoxPlot, setTimeSeriesChartBoxPlot] = useState(false);

  const [meanSymbol, setMeanSymbol] = useState(true);
  const [meanConnected, setMeanConnected] = useState(true);
  const [showOutliers, setShowOutliers] = useState(true);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const [d, c] = fieldType(data);
    setCategoryFields(c);
    setDataFields(c);
  }, [data]);

  useEffect(() => {
    if ((categoryFields + ["__NO__CATEGORY__"]).indexOf(categoryField) === -1) {
      setCategoryField("__NO__CATEGORY__");
    }
  }, [categoryField, categoryFields]);

  useEffect(() => {
    if (dataFields.indexOf(dataField) === -1) {
      if (dataFields.length > 0) {
        setDataField(dataFields[0]);
      }
    }
  }, [dataField, dataFields]);

  const newDataset = () => {
    let data = d3.range(50).map((x) => {
      return { data: "", category: "" };
    });
    data.columns = ["Data", "Category"];
    setData(data);
    // setDataFields(["data"]);
  };

  var pareto_data = {};
  var scatter_data = [];
  var time_series_data = [];

  var dataLabel = dataField;
  var categoryLabel = categoryField;

  console.log(rowData);

  if (ParetoChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        if (rowValue[dataLabel] != null) {

          var dataX = rowValue[dataLabel].trim();
          if (pareto_data[dataX] == null) {
            pareto_data[dataX] = 0;
          }
          if (yAxisLabel === "__NO__CATEGORY__")
            pareto_data[dataX] += 1;
          else
            pareto_data[dataX] += parseFloat(rowValue[yAxisLabel]);
        }
      }
    });
  }

  if (ScatterChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        if (rowValue[dataLabel] != null && rowValue[categoryLabel] != null) {
          var scatter = [parseFloat(rowValue[dataLabel]), parseFloat(rowValue[categoryLabel])];
          scatter_data.push(scatter);
        }
      }
    });
  }

  if (TimeSeriesChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        if (rowValue[dataLabel] != null && rowValue[categoryLabel] != null) {
          // if (date.indexOf('-') >= 0) {
          //   date = date.split('-');
          //   var time_series = [Date.UTC(date[0],date[1] - 1,date[2]), parseFloat(rowValue[categoryLabel])];
          //   time_series_data.push(time_series);
          // }
          var time_series = [rowValue[dataLabel], rowValue[categoryLabel]];
          time_series_data.push(time_series);
        }
      }
    });
  }
  var meta = {};
  meta["xAxisLabel"] = xAxisLabel;
  meta["yAxisLabel"] = yAxisLabel;
  meta["title"] = title;


  if (step === 0) {
    return (
      <>
        <Dialog open={loading}>
          <DialogContent>Loading</DialogContent>
        </Dialog>
        <form sx={{ width: "100%" }}>
          <Button
            variant="contained"
            component="label"
            fullWidth
            startIcon={<UploadFile />}
          >
            Upload
            <input
              id="fileInput"
              hidden
              type="file"
              accept=".xls,.xlsx,.csv,.tsv,text/*"
              onChange={(e) => {
                setLoading(true);
                const file = e.target.files[0];
                readFileData(file).then((newData) => {
                  newData = newData.slice(0, 300);
                  newData.columns = Object.keys(newData[0]);
                  setCategoryField(newData.columns[0]);
                  setDataField(newData.columns[1]);
                  setData(newData);
                  setLoading(false);
                });
              }}
            />
          </Button>

          <Button
            variant="contained"
            component="label"
            fullWidth
            startIcon={<Dataset />}
            onClick={newDataset}
            sx={{ marginTop: 1 }}
          >
            Clear for -Manual- Entry
          </Button>

          <EditableTable
            data={data}
            setData={setRowData}
            dataFields={dataFields}
          />
          <Button
            onClick={() => {
              setStep(1);
            }}
            fullWidth
            variant="contained"
            startIcon={<ArrowForward />}
          >
            Continue
          </Button>
        </form>
        <br style={{ marginBottom: "60px" }} />
        <center>
          <a href="http://www.dotitout.co/">
            <img src="dotitout.png" width="90%" alt="Dot it out" />
            Visit
          </a>
        </center>
      </>
    );
  }

  if (step === 1) {
    return (
      <>
        <Button
          onClick={() => {
            setStep(0);
          }}
          fullWidth
          startIcon={<ArrowBack />}
        >
          Go Back
        </Button>
        <form onSubmit={() => setStep(2)}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Data Selection
          </Typography>
          <TextField
            label="Data"
            id="data-field"
            value={dataField}
            margin="normal"
            select
            fullWidth
            onChange={(e) => {
              setDataField(e.target.value);
              setXAxisLabel(e.target.value);
            }}
          >
            {dataFields.map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={xAxisLabel}
            onChange={(e) => setXAxisLabel(e.target.value)}
            label="X Axis Label"
            margin="normal"
          />
          <TextField
            label="Category"
            id="category-field"
            value={categoryField}
            margin="normal"
            select
            fullWidth
            onChange={(e) => {
              setCategoryField(e.target.value);
              setYAxisLabel(e.target.value);
            }}
          >
            <MenuItem value={"__NO__CATEGORY__"}>No Category</MenuItem>
            {categoryFields.map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={yAxisLabel}
            onChange={(e) => setYAxisLabel(e.target.value)}
            label="Y Axis Label"
            margin="normal"
          />
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            label="Title"
            margin="normal"
            fullWidth
          />
          <Typography variant="h5" sx={{ textAlign: "center", marginTop: 4 }}>
            Process Selection
          </Typography>

          <TextField
            value={lsl}
            onChange={(e) => setLSL(e.target.value)}
            type="number"
            label="Lower Specification Limit"
            margin="normal"
            fullWidth
          />
          <TextField
            value={targetMean}
            onChange={(e) => setTargetMean(e.target.value)}
            type="number"
            label="Target Mean"
            margin="normal"
            fullWidth
          />
          <TextField
            value={usl}
            onChange={(e) => setUSL(e.target.value)}
            type="number"
            label="Upper Specification Limit"
            margin="normal"
            fullWidth
          />

          <Typography variant="h5" sx={{ textAlign: "center", marginTop: 4 }}>
            Customize Layout
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={classicDesign}
                onChange={(e) => setClassicDesign(e.target.checked)}
              />
            }
            label={
              <>
                Check for <b>Classic Gray Design</b>
              </>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeMarkers}
                onChange={(e) => setIncludeMarkers(e.target.checked)}
              />
            }
            label={
              <>
                Check to <b>Increase Specification Range</b>
              </>
            }
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={outputBoxPlot}
                  onChange={(e) => {
                    setOutputBoxPlot(e.target.checked);
                    setParetoChartBoxPlot(0);
                    setScatterChartBoxPlot(0);
                    setTimeSeriesChartBoxPlot(0);
                  }}
                />
              }
              label="Output Box Plot"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ParetoChartBoxPlot}
                  onChange={(e) => {
                    setParetoChartBoxPlot(e.target.checked)
                    setOutputBoxPlot(0);
                    setScatterChartBoxPlot(0);
                    setTimeSeriesChartBoxPlot(0);
                  }}
                />
              }
              label="Pareto Chart"
            />{" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={ScatterChartBoxPlot}
                  onChange={(e) => {
                    setScatterChartBoxPlot(e.target.checked);
                    setOutputBoxPlot(0);
                    setParetoChartBoxPlot(0);
                    setTimeSeriesChartBoxPlot(0);
                  }}
                />
              }
              label="Scatter Plot"
            />{" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={TimeSeriesChartBoxPlot}
                  onChange={(e) => {
                    setTimeSeriesChartBoxPlot(e.target.checked)
                    setOutputBoxPlot(0);
                    setParetoChartBoxPlot(0);
                    setScatterChartBoxPlot(0);
                  }}
                />
              }
              label="Time Series Chart"
            />
          </FormGroup>

          {outputBoxPlot ? (
            <BoxOptions
              data={{
                meanSymbol,
                setMeanSymbol,
                meanConnected,
                setMeanConnected,
                showOutliers,
                setShowOutliers,
              }}
            />
          ) : (
            ""
          )}

          <Button
            type="submit"
            onClick={() => {
              setStep(2);
            }}
            fullWidth
            variant="contained"
            startIcon={<ArrowForward />}
            sx={{ marginTop: 4 }}
          >
            Continue
          </Button>
        </form>
      </>
    );
  }

  return (
    <div>
      <Button
        onClick={() => {
          setStep(1);
        }}
        fullWidth
        startIcon={<ArrowBack />}
      >
        Go Back
      </Button>
      {

        ParetoChartBoxPlot ?

          <Paretochart pareto_data={pareto_data} meta={meta} /> :
          (
            ScatterChartBoxPlot ? (<ScatterPlot
              scatter_data={scatter_data}
              meta={meta}
            />) :
              (
                TimeSeriesChartBoxPlot ? (<TimeSeriesChart
                  series={time_series_data}
                  meta={meta}
                  lsl={lsl}
                  usl={usl}
                  targetMean={targetMean}
                />) :
                  (
                    outputBoxPlot ? (
                      <BoxPlot
                        rowData={rowData}
                        meta={Object.assign({
                          dataField,
                          categoryField,

                          title,
                          xAxisLabel,
                          yAxisLabel,
                          usl,
                          lsl,
                          targetMean,
                          classicDesign,
                          includeMarkers,
                          showOutliers,
                          meanSymbol,
                          meanConnected,
                        })}
                        mobile
                      />
                    ) : (
                      <Plot
                        rowData={rowData}
                        meta={{
                          lsl,
                          usl,
                          targetMean,
                          dataField,
                          categoryField,
                          classicDesign,
                          includeMarkers,
                          title,
                          xAxisLabel,
                          yAxisLabel,
                          displayStatisticalPanels: true,
                          displayMean: true,
                          displayMedian: true,
                          displayMode: true,
                        }}
                        mobile
                      />
                    )
                  )
              )
          )
      }
      <Button
        onClick={() => {
          setStep(1);
        }}
        fullWidth
        startIcon={<ArrowBack />}
      >
        Go Back
      </Button>
    </div>
  );
};

export default MyMobileApp;
