import * as d3 from "d3";
import { read, utils } from "xlsx";

const getData = () => {
  const csvData = `sepalLength,sepalWidth,petalLength,petalWidth,species
  5.1,3.5,1.4,0.2,setosa
  4.9,3,1.4,0.2,setosa
  4.7,3.2,1.3,0.2,setosa
  4.6,3.1,1.5,0.2,setosa
  5,3.6,1.4,0.2,setosa
  5.4,3.9,1.7,0.4,setosa
  4.6,3.4,1.4,0.3,setosa
  5,3.4,1.5,0.2,setosa
  4.4,2.9,1.4,0.2,setosa
  4.9,3.1,1.5,0.1,setosa
  5.4,3.7,1.5,0.2,setosa
  4.8,3.4,1.6,0.2,setosa
  4.8,3,1.4,0.1,setosa
  4.3,3,1.1,0.1,setosa
  5.8,4,1.2,0.2,setosa
  5.7,4.4,1.5,0.4,setosa
  5.4,3.9,1.3,0.4,setosa
  5.1,3.5,1.4,0.3,setosa
  5.7,3.8,1.7,0.3,setosa
  5.1,3.8,1.5,0.3,setosa
  5.4,3.4,1.7,0.2,setosa
  5.1,3.7,1.5,0.4,setosa
  4.6,3.6,1,0.2,setosa
  5.1,3.3,1.7,0.5,setosa
  4.8,3.4,1.9,0.2,setosa
  5,3,1.6,0.2,setosa
  5,3.4,1.6,0.4,setosa
  5.2,3.5,1.5,0.2,setosa
  5.2,3.4,1.4,0.2,setosa
  4.7,3.2,1.6,0.2,setosa
  4.8,3.1,1.6,0.2,setosa
  5.4,3.4,1.5,0.4,setosa
  5.2,4.1,1.5,0.1,setosa
  5.5,4.2,1.4,0.2,setosa
  4.9,3.1,1.5,0.2,setosa
  5,3.2,1.2,0.2,setosa
  5.5,3.5,1.3,0.2,setosa
  4.9,3.6,1.4,0.1,setosa
  4.4,3,1.3,0.2,setosa
  5.1,3.4,1.5,0.2,setosa
  5,3.5,1.3,0.3,setosa
  4.5,2.3,1.3,0.3,setosa
  4.4,3.2,1.3,0.2,setosa
  5,3.5,1.6,0.6,setosa
  5.1,3.8,1.9,0.4,setosa
  4.8,3,1.4,0.3,setosa
  5.1,3.8,1.6,0.2,setosa
  4.6,3.2,1.4,0.2,setosa
  5.3,3.7,1.5,0.2,setosa
  5,3.3,1.4,0.2,setosa
  7,3.2,4.7,1.4,versicolor
  6.4,3.2,4.5,1.5,versicolor
  6.9,3.1,4.9,1.5,versicolor
  5.5,2.3,4,1.3,versicolor
  6.5,2.8,4.6,1.5,versicolor
  5.7,2.8,4.5,1.3,versicolor
  6.3,3.3,4.7,1.6,versicolor
  4.9,2.4,3.3,1,versicolor
  6.6,2.9,4.6,1.3,versicolor
  5.2,2.7,3.9,1.4,versicolor
  5,2,3.5,1,versicolor
  5.9,3,4.2,1.5,versicolor
  6,2.2,4,1,versicolor
  6.1,2.9,4.7,1.4,versicolor
  5.6,2.9,3.6,1.3,versicolor
  6.7,3.1,4.4,1.4,versicolor
  5.6,3,4.5,1.5,versicolor
  5.8,2.7,4.1,1,versicolor
  6.2,2.2,4.5,1.5,versicolor
  5.6,2.5,3.9,1.1,versicolor
  5.9,3.2,4.8,1.8,versicolor
  6.1,2.8,4,1.3,versicolor
  6.3,2.5,4.9,1.5,versicolor
  6.1,2.8,4.7,1.2,versicolor
  6.4,2.9,4.3,1.3,versicolor
  6.6,3,4.4,1.4,versicolor
  6.8,2.8,4.8,1.4,versicolor
  6.7,3,5,1.7,versicolor
  6,2.9,4.5,1.5,versicolor
  5.7,2.6,3.5,1,versicolor
  5.5,2.4,3.8,1.1,versicolor
  5.5,2.4,3.7,1,versicolor
  5.8,2.7,3.9,1.2,versicolor
  6,2.7,5.1,1.6,versicolor
  5.4,3,4.5,1.5,versicolor
  6,3.4,4.5,1.6,versicolor
  6.7,3.1,4.7,1.5,versicolor
  6.3,2.3,4.4,1.3,versicolor
  5.6,3,4.1,1.3,versicolor
  5.5,2.5,4,1.3,versicolor
  5.5,2.6,4.4,1.2,versicolor
  6.1,3,4.6,1.4,versicolor
  5.8,2.6,4,1.2,versicolor
  5,2.3,3.3,1,versicolor
  5.6,2.7,4.2,1.3,versicolor
  5.7,3,4.2,1.2,versicolor
  5.7,2.9,4.2,1.3,versicolor
  6.2,2.9,4.3,1.3,versicolor
  5.1,2.5,3,1.1,versicolor
  5.7,2.8,4.1,1.3,versicolor
  6.3,3.3,6,2.5,virginica
  5.8,2.7,5.1,1.9,virginica
  7.1,3,5.9,2.1,virginica
  6.3,2.9,5.6,1.8,virginica
  6.5,3,5.8,2.2,virginica
  7.6,3,6.6,2.1,virginica
  4.9,2.5,4.5,1.7,virginica
  7.3,2.9,6.3,1.8,virginica
  6.7,2.5,5.8,1.8,virginica
  7.2,3.6,6.1,2.5,virginica
  6.5,3.2,5.1,2,virginica
  6.4,2.7,5.3,1.9,virginica
  6.8,3,5.5,2.1,virginica
  5.7,2.5,5,2,virginica
  5.8,2.8,5.1,2.4,virginica
  6.4,3.2,5.3,2.3,virginica
  6.5,3,5.5,1.8,virginica
  7.7,3.8,6.7,2.2,virginica
  7.7,2.6,6.9,2.3,virginica
  6,2.2,5,1.5,virginica
  6.9,3.2,5.7,2.3,virginica
  5.6,2.8,4.9,2,virginica
  7.7,2.8,6.7,2,virginica
  6.3,2.7,4.9,1.8,virginica
  6.7,3.3,5.7,2.1,virginica
  7.2,3.2,6,1.8,virginica
  6.2,2.8,4.8,1.8,virginica
  6.1,3,4.9,1.8,virginica
  6.4,2.8,5.6,2.1,virginica
  7.2,3,5.8,1.6,virginica
  7.4,2.8,6.1,1.9,virginica
  7.9,3.8,6.4,2,virginica
  6.4,2.8,5.6,2.2,virginica
  6.3,2.8,5.1,1.5,virginica
  6.1,2.6,5.6,1.4,virginica
  7.7,3,6.1,2.3,virginica
  6.3,3.4,5.6,2.4,virginica
  6.4,3.1,5.5,1.8,virginica
  6,3,4.8,1.8,virginica
  6.9,3.1,5.4,2.1,virginica
  6.7,3.1,5.6,2.4,virginica
  6.9,3.1,5.1,2.3,virginica
  5.8,2.7,5.1,1.9,virginica
  6.8,3.2,5.9,2.3,virginica
  6.7,3.3,5.7,2.5,virginica
  6.7,3,5.2,2.3,virginica
  6.3,2.5,5,1.9,virginica
  6.5,3,5.2,2,virginica
  6.2,3.4,5.4,2.3,virginica
  5.9,3,5.1,1.8,virginica`;

  return d3.csvParse(csvData);
};

const getDataPizza = () => {
  const news = `Name,DeliveryTime
  Ann,25.647
  Ann,31.161
  Ann,31.261
  Ann,30.828
  Ann,29.657
  Ann,31.809
  Ann,32.965
  Ann,23.804
  Ann,27.513
  Ann,30.056
  Ann,31.511
  Ann,24.039
  Ann,27.922
  Ann,26.139
  Ann,30.457
  Ann,30.353
  Ann,34.020
  Ann,28.796
  Ann,26.653
  Ann,26.395
  Ann,29.592
  Ann,32.795
  Ann,37.334
  Ann,28.001
  Ann,32.809
  Ann,29.523
  Ann,28.950
  Ann,26.456
  Ann,26.447
  Ann,30.287
  Ann,28.971`;
  const csvData = `Name,DeliveryTime
  Mike,36.4
  Mike,35.5
  Mike,31.1
  Mike,29.1
  Mike,29.6
  Mike,28.5
  Mike,28.3
  Sally,31.6
  Sally,27.5
  Sally,24.5
  Sally,28.6
  Sally,31.3
  Sally,28.1
  Sally,26.9
  John,26.9
  John,29.2
  John,29.3
  John,30.2
  John,29.2
  John,30.7
  John,29.2
  Bob,27.1
  Bob,20.0
  Bob,10.3
  Bob,52.7
  Bob,36.9
  Bob,22.4
  Bob,30.0`;

  return d3.csvParse(csvData);
};

const isNumber = (x) => {
  return !isNaN(parseFloat(x, 10));
};

const fieldType = (data) => {
  let numberColumns = data.columns;
  let categoryColumns = data.columns;
  let distinctValues = data.columns.reduce((a, f) => {
    a[f] = [];
    return a;
  }, {});

  if (
    data.columns.length === 2 &&
    data.columns[0] === "Data" &&
    data.columns[1] === "Category"
  ) {
    return [["Data"], ["Category"]];
  }

  for (let row of data) {
    for (let field of categoryColumns) {
      if (distinctValues[field].indexOf(row[field]) < 0) {
        distinctValues[field].push(row[field]);
      }
    }
    numberColumns = numberColumns.filter(
      (field) => isNumber(row[field]) || row[field] === ""
    );
    categoryColumns = categoryColumns.filter(
      (field) => distinctValues[field].length
    );
  }

  return [numberColumns, categoryColumns];
};

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const workbook = read(e.target.result,{type:'binary',cellText:false,cellDates:true});
      const sheets = workbook["Sheets"];
      const firstSheet = Object.keys(sheets)[0];

      let newData = utils.sheet_to_json(sheets[firstSheet],{raw:false,dateNF:'yyyy-mm-dd'});
      resolve(newData);
    };
    reader.readAsArrayBuffer(file);
  });
};

const sortArraysSync = (array1, array2) => {
  const indices = array2.map((_, index) => index);
  indices.sort((a, b) => array2[b] - array2[a]);

  const sortedArray1 = indices.map(index => array1[index]);
  const sortedArray2 = indices.map(index => array2[index]);
  
  return [sortedArray1, sortedArray2];
}

export { fieldType, getDataPizza as getData, readFileData, sortArraysSync };
