import Header from "../components/Header";
import MyBrowserApp from "../components/MyBrowserApp";
import StudentHeader from "../components/StudentHeader";
import MyBrowserStudentApp from "../components/MyBrowserStudentApp";
import { React, useState } from "react";
const PunchTool = () => {
    const [StudentTool, setStudentTool] = useState(false);
    return (
        <div>
            {
                !StudentTool ? (
                    <div>
                        <Header onClickStudentTool={() => setStudentTool(true)} />
                        <MyBrowserApp />
                    </div>
                ) : (
                    <div>
                        <StudentHeader onClickStudentTool={() => setStudentTool(false)}/>
                        <MyBrowserStudentApp />
                    </div>
                )
            }
        </div>
    );
}
export default PunchTool;