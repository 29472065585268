const handleBackground = (svg, classicDesign) => {
  var defs = svg.append("defs");

  var gradient = defs
    .append("linearGradient")
    .attr("id", "svgGradient")
    .attr("x1", "0%")
    .attr("x2", "100%")
    .attr("y1", "0%")
    .attr("y2", "100%");

  gradient
    .append("stop")
    .attr("offset", "0%")
    .attr("stop-color", "lightgreen")
    .attr("stop-opacity", 1);

  gradient
    .append("stop")
    .attr("offset", "100%")
    .attr("stop-color", "pink")
    .attr("stop-opacity", 1);

  svg
    .append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", 900)
    .attr("height", 500)
    .attr("fill", classicDesign ? "lightgray" : "url(#svgGradient)");
};

export { handleBackground };
