import Home from "../home.png";
import { AppBar, Toolbar, Box } from "@mui/material";

const StudentHeader = (props) => {
    return (
        <AppBar position="static" sx={{ minHeight: 170, backgroundColor: "white" }}>
            <Toolbar>
                <Box style={{ marginRight: "100px", marginLeft: "70px" }}>
                    <Box
                        component="img"
                        sx={{
                            height: 150
                        }}
                        alt="Your logo"
                        src="logo1.png"
                    />
                </Box>
                <Box style={{ marginRight: "100px" }}>
                    <Box
                        component="img"
                        sx={{
                            height: 110
                        }}
                        alt="Your logo"
                        src={Home}
                    />
                    <Box sx={{ textAlign: "center", marginBottom: "15px"}}>
                        <a href="https://butleractive.com/" style={{ fontSize: "20px" }}>HOME</a>
                    </Box>
                </Box>
                <Box style={{ marginRight: "100px" }}>
                    <Box
                        component="img"
                        sx={{
                            height: 165
                        }}
                        alt="Your logo"
                        src="student.png"
                        onClick={(event) => {
                            props.onClickStudentTool();
                        }}
                    />
                </Box>
                <Box style={{ marginRight: "150px" }}>
                    <Box
                        component="img"
                        sx={{
                            height: 140,
                        }}
                        alt="Your logo"
                        src="logo4.png"
                    />
                </Box>
                <Box sx={{ marginTop: "30px" }}>
                    <Box>
                        <a style={{ fontSize: "25px", textDecoration: "none", color: "black" }} href="https://butleractive.com/" rel="noreferrer" target="_blank">www.butleractive.com</a>
                    </Box>
                    <Box>
                        <p style={{ fontSize: "25px", color: "black" }}>Creator: Tyrone Butler, EMBB, Lean Six Sigma</p>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default StudentHeader;