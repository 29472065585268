import React from "react";
import Highcharts from "highcharts/highstock";
import pareto from "highcharts/modules/pareto";
import HighchartsReact from "highcharts-react-official";
import jStat from "jStat";
import { SimpleLinearRegression as LinearRegression } from 'ml-regression';
import regression from 'regression';
import { Button } from "@mui/material";
import { useState } from "react";
function calculateCorrelation(x, y) {
    if (x.length !== y.length) {
        throw new Error('Data sets must have the same length');
    }

    const n = x.length;

    // Calculate the mean of x and y
    const meanX = x.reduce((sum, value) => sum + value, 0) / n;
    const meanY = y.reduce((sum, value) => sum + value, 0) / n;

    // Calculate the covariance and variances
    let covariance = 0;
    let varianceX = 0;
    let varianceY = 0;

    for (let i = 0; i < n; i++) {
        const deviationX = x[i] - meanX;
        const deviationY = y[i] - meanY;

        covariance += deviationX * deviationY;
        varianceX += deviationX ** 2;
        varianceY += deviationY ** 2;
    }

    covariance /= n;
    varianceX /= n;
    varianceY /= n;

    // Calculate the correlation coefficient
    const correlation = covariance / Math.sqrt(varianceX * varianceY);

    return correlation;
}

function linearRegression(x, y) {
    var N = x.length;
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var sum_yy = 0;

    for (var i = 0; i < N; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += x[i] * y[i];
        sum_xx += x[i] * x[i];
        sum_yy += y[i] * y[i];
    }

    var slope = (N * sum_xy - sum_x * sum_y) / (N * sum_xx - sum_x * sum_x);
    var intercept = (sum_y - slope * sum_x) / N;
    var r = (N * sum_xy - sum_x * sum_y) / Math.sqrt(
        (N * sum_xx - sum_x * sum_x) * (N * sum_yy - sum_y * sum_y)
    );

    var result = [];
    for (var i = 0; i < N; i++) {
        result.push({ x: x[i], y: slope * x[i] + intercept });
    }
    return { result: result, slope: slope, intercept: intercept, r: r };
}

export default function ScatterPlot(props) {

    const [LineOption, setLineOption] = useState(true);

    pareto(Highcharts);
    console.log(props.scatter_data);

    var dataSet1 = [];
    var dataSet2 = [];
    for (let i = 0; i < props.scatter_data.length; i++) {
        dataSet1.push(props.scatter_data[i][0])
        dataSet2.push(props.scatter_data[i][1]);
    }

    console.log(dataSet1);
    console.log(dataSet2);

    var correlationCoefficient = calculateCorrelation(dataSet1, dataSet2);
    console.log('Correlation coefficient:', correlationCoefficient);

    var result = regression.linear(props.scatter_data);
    var slope = result.equation[0];
    var intercept = result.equation[1];

    const x1 = jStat.min(dataSet1);
    const x2 = jStat.max(dataSet1);

    if (isNaN(correlationCoefficient)) correlationCoefficient = 0;
    if (isNaN(result.r2)) result.r2 = 0;
    if (slope === 0 && isNaN(intercept))
        result.string = "Y = 0";

    var trendline = linearRegression(dataSet1, dataSet2);

    console.log(slope, intercept);
    console.log(trendline.slope, trendline.intercept);

    slope = trendline.slope;
    intercept = trendline.intercept;

    var regressionEquation = "";
    if (slope === 0 && intercept === 0)
        regressionEquation = "Y = 0";
    if (slope === 0 && intercept !== 0)
        regressionEquation = "Y = " + intercept.toFixed(2);
    if (slope !== 0 && intercept !== 0)
        regressionEquation = "Y = " + slope.toFixed(2) + "X + " + intercept.toFixed(2);
    if (slope === 1 && intercept === 0)
        regressionEquation = "Y = X";
    if ( isNaN(slope) && isNaN(intercept) )
        regressionEquation = "Y = 0";
    const options = {
        chart: {
            type: 'scatter',
            zoomType: 'xy',
            // backgroundColor: props.classicDesign ? 'lightgray' : '#FFF'
        },
        credits: {
            enabled: false // Disables the credits watermark
        },
        title: {
            text: props.meta.title
        },
        subtitle: {
            //text: "Correlation:  r = " + correlationCoefficient.toFixed(2) + "<br/>" + "Regression:  R² = " + result.r2 + "<br/>" + "Prediction Equation: " + regressionEquation,
            text: `<span style="color: green;">Correlation:  r = ${correlationCoefficient.toFixed(2)}</span><br><span style="color: blue;">Regression:  R² = ${result.r2}</span><br><span style="color: red;">Prediction Equation : ${regressionEquation}</span>`,
            style: {
                color: "red",
                fontSize: "20px"
            },
            useHtml: true
        },
        xAxis: {
            title: {
                enabled: true,
                text: props.meta.xAxisLabel,
                style: {
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: 'black',
                }
            },
            labels: {
                formatter: function () {
                    if (isNaN(parseFloat(this.value)))
                        return this.value;
                    else
                        return Highcharts.numberFormat(this.value, 3);
                }
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: [
            {
                title: {
                    text: props.meta.yAxisLabel,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: 'black',
                    }
                }
            }
        ],
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 100,
            y: 70,
            floating: true,
            enabled: false,
            backgroundColor: (
                Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
                '#FFFFFF',
            borderWidth: 1
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormat: '{point.x} , {point.y}'
                }
            }
        },
        colors: ['#F00A', '#00FA', '#6F6A', '#036', '#000'],
        series: [
            {
                name: "Scatter Data",
                data: props.scatter_data,
                color: '#00FA',
            },
            {
                name: "Linear regression",
                data: [[x1, x1 * slope + intercept], [x2, x2 * slope + intercept]],
                color: "#F00",
                type: 'line',
                visible: LineOption,
                marker: {
                    enabled: false // Set this to false to hide the dots
                }
            }
        ]
    };
    return (
        <div>
            <Button variant="contained" color="error" style={{ marginLeft: "40px" }} onClick={(event) => {
                setLineOption(!LineOption);
            }}>Fitted Line</Button>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
}
