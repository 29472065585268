import React, { useState, useRef } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Download } from "@mui/icons-material";
import XLSX from "xlsx";

import Plot from "./Plot";
import BoxPlot from "./BoxPlot";
import { paperStyle, numberStyle, numberGrayStyle, numberGreenStyle } from "./styles";

import Ratings from "./Ratings";
import Paretochart from "./Paretochart";
import ScatterPlot from "./Scatterplot";
import TimeSeriesChart from "./TimeSeriesChart";
import html2canvas from 'html2canvas';
import clipboardCopy from 'clipboard-copy';
import HistogramChart from './HistogramChart';

const BoxOptions = (props) => {
  const { data } = props;

  const {
    meanSymbol,
    setMeanSymbol,
    meanConnected,
    setMeanConnected,
    showOutliers,
    setShowOutliers,
  } = data;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={meanSymbol}
            onChange={(e) => setMeanSymbol(e.target.checked)}
          />
        }
        label="Add Mean Symbol"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={meanConnected}
            onChange={(e) => setMeanConnected(e.target.checked)}
          />
        }
        label="Add Mean Connect Line"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showOutliers}
            onChange={(e) => setShowOutliers(e.target.checked)}
          />
        }
        label="Show Outliers"
      />
    </FormGroup>
  );
};

const DotOptions = (props) => {
  const { data } = props;
  const {
    displayMean,
    setDisplayMean,
    displayMedian,
    setDisplayMedian,
    displayMode,
    setDisplayMode,
  } = data;
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={displayMean}
            onChange={(e) => setDisplayMean(e.target.checked)}
          />
        }
        label="Display Mean Line "
      />{" "}
      <FormControlLabel
        control={
          <Checkbox
            checked={displayMedian}
            onChange={(e) => setDisplayMedian(e.target.checked)}
          />
        }
        label="Display Median Line"
      />{" "}
      <FormControlLabel
        control={
          <Checkbox
            checked={displayMode}
            onChange={(e) => setDisplayMode(e.target.checked)}
          />
        }
        label="Display Mode Line"
      />
    </FormGroup>
  );
};
const captureDivAsImage = () => {
  const divElement = document.getElementById('chart_area'); // Replace 'your-div-id' with the actual ID of your <div> element

  html2canvas(divElement)
    .then((canvas) => {
      const dataURL = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'download.png';
      link.click();
    });
};
const DownloadListItem = () => {
  // const { element } = props;

  return (
    <ListItem disablePadding>
      <ListItemButton
        download="dotplot.png"
        onClick={() => {
          // const element = document.getElementById("area")
          // saveSvgAsPng.saveSvgAsPng(element, "dotplot.png");
          captureDivAsImage();
        }}
      >
        <ListItemIcon>
          <ImageIcon />
        </ListItemIcon>
        Download PNG
      </ListItemButton>
    </ListItem>
  );
};

const captureDivAsPng = (props) => {
  html2canvas(props.chartAreaRef.current).then((canvas) => {
    // Convert canvas to PNG image data
    const dataURL = canvas.toDataURL('image/png');

    // Copy the image data to the clipboard
    // (You can also save it as a file or use it as an image source)
    navigator.clipboard.writeText(dataURL);
  });
};
const CopyToClipboardItem = (props) => {
  // const { element } = props;
  return (
    <ListItem disablePadding>
      <ListItemButton
        download="dotplot.png"
        onClick={() => {
          // const element = document.getElementById("area")
          // saveSvgAsPng
          //   .svgAsDataUri(element, "dotplot.png")
          //   .then((imageString) => {
          //     let image = document.createElement("img");
          //     image.onload = (e) => {
          //       const image = e.target;
          //       const canvas = document.createElement("canvas");
          //       canvas.width = image.naturalWidth;
          //       canvas.height = image.naturalHeight;
          //       const context = canvas.getContext("2d");
          //       context.drawImage(image, 0, 0);
          //       canvas.toBlob((blob) => {
          //         navigator.clipboard.write([
          //           new window.ClipboardItem({
          //             [blob.type]: blob,
          //           }),
          //         ]);
          //       });
          //     };
          //     image.src = imageString;
          //   });
          const divElement = document.getElementById('chart_area');
          html2canvas(divElement).then(canvas => {
            canvas.toBlob(blob => {
              const image = URL.createObjectURL(blob);
              clipboardCopy(image);
              navigator.clipboard.write([
                new window.ClipboardItem({
                  [blob.type]: blob,
                }),
              ])
            }, 'image/png');
          });
        }}
      >
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
        Copy PNG to Clipboard
      </ListItemButton>
    </ListItem>
  );
};

const DonwloadDataItem = (props) => {
  // const { element } = props;
  return (
    <ListItem disablePadding>
      <ListItemButton
        download="download.xlsx"
        onClick={() => {
          const worksheet = XLSX.utils.json_to_sheet(props.rowData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const url = URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.download = "download.xlsx";
          link.click();
          URL.revokeObjectURL(url);
        }}
      >
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        Download Data
      </ListItemButton>
    </ListItem>
  );
};

const BrowserPlot = (props) => {
  const { rowData, meta } = props;
  const [targetMean, setTargetMean] = useState(30);
  const [lsl, setLSL] = useState(15);
  const [usl, setUSL] = useState(40);

  const [classicDesign, setClassicDesign] = useState(false);
  const [includeMarkers, setIncludeMarkers] = useState(false);
  const [displayStatisticalPanels, setDisplayStatisticalPanels] =
    useState(true);

  const [displayMean, setDisplayMean] = useState(true);
  const [displayMode, setDisplayMode] = useState(true);
  const [displayMedian, setDisplayMedian] = useState(true);

  const [outputBoxPlot, setOutputBoxPlot] = useState(false);
  const [ParetoChartBoxPlot, setParetoChartBoxPlot] = useState(false);
  const [ScatterChartBoxPlot, setScatterChartBoxPlot] = useState(false);
  const [TimeSeriesChartBoxPlot, setTimeSeriesChartBoxPlot] = useState(false);
  const [HistogramChartBoxPlot, setHistogramChartBoxPlot] = useState(false);

  const [meanSymbol, setMeanSymbol] = useState(true);
  const [meanConnected, setMeanConnected] = useState(true);
  const [showOutliers, setShowOutliers] = useState(true);
  const chartAreaRef = useRef(null);

  var pareto_data = {};
  var scatter_data = [];
  var time_series_data = [];
  var histogram_data = [];

  var dataLabel = meta.dataField;
  var categoryLabel = meta.categoryField;

  if (ParetoChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        if (rowValue[dataLabel] != null) {

          var dataX = rowValue[dataLabel].trim();
          if (pareto_data[dataX] == null) {
            pareto_data[dataX] = 0;
          }
          if (props.meta.yAxisLabel === "__NO__CATEGORY__")
            pareto_data[dataX] += 1;
          else
            pareto_data[dataX] += parseFloat(rowValue[props.meta.yAxisLabel]);
        }
      }
    });
  }

  if (ScatterChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        if (rowValue[dataLabel] != null && rowValue[categoryLabel] != null) {
          var scatter = [parseFloat(rowValue[dataLabel]), parseFloat(rowValue[categoryLabel])];
          scatter_data.push(scatter);
        }
      }
    });
  }

  if (TimeSeriesChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        var time_series = [];
        if (rowValue[dataLabel] != null && rowValue[categoryLabel] != null) {
          // if (date.indexOf('-') >= 0) {
          //   date = date.split('-');
          //   var time_series = [Date.UTC(date[0],date[1] - 1,date[2]), parseFloat(rowValue[categoryLabel])];
          //   time_series_data.push(time_series);
          // }
          time_series = [rowValue[dataLabel], rowValue[categoryLabel]];
          time_series_data.push(time_series);
        }
        if (dataLabel === "No X Data Defined") {
          time_series = [index + 1, rowValue[categoryLabel]];
          time_series_data.push(time_series);
        }
      }
    });
  }
  if (HistogramChartBoxPlot) {
    Object.keys(rowData).map((value, index) => {
      if (value !== "columns") {
        var rowValue = rowData[value];
        if (rowValue[dataLabel] != null) {

          // var dataX = rowValue[dataLabel].trim();
          // if (props.meta.yAxisLabel === "__NO__CATEGORY__")
          //   pareto_data[dataX] += 1;
          // else
          histogram_data.push(parseFloat(rowValue[props.meta.xAxisLabel]))
        }
      }
    });
  }
  return (
    <>
      <Paper elevation={3} sx={paperStyle}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Typography
              variant="h6"
              sx={Object.assign(classicDesign ? numberGrayStyle : numberGreenStyle, { overflow: "hidden" })}
            >
              3. Specifications
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={lsl}
              onChange={(e) => setLSL(e.target.value)}
              type="number"
              label={<b style={{ color: "red", fontSize: "19px" }}>Lower Specification Limit</b>}
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={targetMean}
              onChange={(e) => setTargetMean(e.target.value)}
              type="number"
              label={<b style={{ color: "red", fontSize: "19px" }}>Target Mean</b>}
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={usl}
              onChange={(e) => setUSL(e.target.value)}
              type="number"
              label={<b style={{ color: "red", fontSize: "19px" }}>Upper Specification Limit</b>}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container>
        <Grid item xs={10}>
          <Paper elevation={2} sx={paperStyle}>
            <Typography variant="h6" sx={classicDesign ? numberGrayStyle : numberGreenStyle}>
              4. Evaluate Result
            </Typography>
            <br />
            <div id="chart_area" ref={chartAreaRef}>
              {

                ParetoChartBoxPlot ?

                  <Paretochart pareto_data={pareto_data} meta={meta} classicDesign={classicDesign} /> :
                  (
                    ScatterChartBoxPlot ? (<ScatterPlot classicDesign={classicDesign}
                      scatter_data={scatter_data}
                      meta={meta}
                    />) :
                      (
                        TimeSeriesChartBoxPlot ? (<TimeSeriesChart classicDesign={classicDesign}
                          series={time_series_data}
                          meta={meta}
                          lsl={lsl}
                          usl={usl}
                          targetMean={targetMean}
                        />) :
                          (HistogramChartBoxPlot ? (<HistogramChart classicDesign={classicDesign} histogram_data={histogram_data} meta={meta} />) :
                            (
                              outputBoxPlot ? (
                                <BoxPlot
                                  rowData={rowData}
                                  meta={Object.assign(
                                    { ...meta },
                                    {
                                      usl,
                                      lsl,
                                      targetMean,
                                      classicDesign,
                                      includeMarkers,
                                      showOutliers,
                                      meanSymbol,
                                      meanConnected,
                                    }
                                  )}
                                />
                              ) : <Plot
                                rowData={rowData}
                                meta={Object.assign(
                                  { ...meta },
                                  {
                                    usl,
                                    lsl,
                                    targetMean,
                                    classicDesign,
                                    includeMarkers,
                                    displayStatisticalPanels,
                                    displayMean,
                                    displayMedian,
                                    displayMode,
                                  }
                                )}
                              />
                            ))
                      )
                  )
              }
            </div>
          </Paper>

        </Grid>
        <Grid item xs={2} sx={{ padding: "20px" }}>
          <Ratings />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <Paper elevation={3} sx={paperStyle}>
            <Typography variant="h6" sx={classicDesign ? numberGrayStyle : numberGreenStyle}>
              5. Share
            </Typography>
            <Grid container>
              <Grid item xs={4}>
                <List>
                  <DownloadListItem />
                  <CopyToClipboardItem chartAreaRef={chartAreaRef} />
                  <DonwloadDataItem rowData={rowData} />
                </List>
              </Grid>

              <Grid item xs={4}>
                {outputBoxPlot ? (
                  <BoxOptions
                    data={{
                      meanSymbol,
                      setMeanSymbol,
                      meanConnected,
                      setMeanConnected,
                      showOutliers,
                      setShowOutliers,
                    }}
                  />
                ) : (
                  <DotOptions
                    data={{
                      displayMean,
                      setDisplayMean,
                      displayMedian,
                      setDisplayMedian,
                      displayMode,
                      setDisplayMode,
                    }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={HistogramChartBoxPlot}
                      onChange={(e) => {
                        setHistogramChartBoxPlot(e.target.checked);
                        setOutputBoxPlot(0);
                        setParetoChartBoxPlot(0);
                        setScatterChartBoxPlot(0);
                        setTimeSeriesChartBoxPlot(0);
                      }}
                    />
                  }
                  label="Histogram Chart"
                />
              </Grid>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={outputBoxPlot}
                        onChange={(e) => {
                          setOutputBoxPlot(e.target.checked);
                          setParetoChartBoxPlot(0);
                          setScatterChartBoxPlot(0);
                          setTimeSeriesChartBoxPlot(0);
                          setHistogramChartBoxPlot(0);
                        }}
                      />
                    }
                    label="Output Box Plot"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ParetoChartBoxPlot}
                        onChange={(e) => {
                          setParetoChartBoxPlot(e.target.checked)
                          setOutputBoxPlot(0);
                          setScatterChartBoxPlot(0);
                          setTimeSeriesChartBoxPlot(0);
                          setHistogramChartBoxPlot(0);
                        }}
                      />
                    }
                    label="Pareto Chart"
                  />{" "}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ScatterChartBoxPlot}
                        onChange={(e) => {
                          setScatterChartBoxPlot(e.target.checked);
                          setOutputBoxPlot(0);
                          setParetoChartBoxPlot(0);
                          setTimeSeriesChartBoxPlot(0);
                          setHistogramChartBoxPlot(0);
                        }}
                      />
                    }
                    label="Scatter Plot"
                  />{" "}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={TimeSeriesChartBoxPlot}
                        onChange={(e) => {
                          setTimeSeriesChartBoxPlot(e.target.checked)
                          setOutputBoxPlot(0);
                          setParetoChartBoxPlot(0);
                          setScatterChartBoxPlot(0);
                          setHistogramChartBoxPlot(0);
                        }}
                      />
                    }
                    label="Time Series Chart"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Paper elevation={3} sx={paperStyle}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={classicDesign}
                  onChange={(e) => {
                    setClassicDesign(e.target.checked);
                    props.setClassicDesign();
                  }}
                  sx={{ marginBottom: 4 }}
                />
              }
              label={
                <>
                  Check for <b>Classic Gray Design</b>
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeMarkers}
                  onChange={(e) => setIncludeMarkers(e.target.checked)}
                  sx={{ marginBottom: 4 }}
                />
              }
              label={
                <>
                  Check to <b>Increase Specification Range</b>
                </>
              }
            />
            {outputBoxPlot ? (
              ""
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayStatisticalPanels}
                    onChange={(e) =>
                      setDisplayStatisticalPanels(e.target.checked)
                    }
                    sx={{ marginBottom: 4 }}
                  />
                }
                label={
                  <>
                    Display <b>Statistical Panels</b>
                  </>
                }
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default BrowserPlot;
export { BoxOptions };
