import React, { useEffect } from "react";
import * as d3 from "d3";

import {
  computeStatistics,
  computePPK,
  cpkToColor,
  stdToColor,
} from "./statistics";

import { handleBackground } from "./plotHelper";

const createXY = (height, width, margin, data, xRange) => {
  const yDomain = data.map((d) => d.y);

  const y = d3
    .scaleBand()
    .align(1)
    .domain(yDomain)
    .range([height - margin.bottom, margin.top]);
  const x = d3
    .scaleLinear()
    .domain(xRange)
    .nice()
    .range([margin.left, width - margin.right]);

  const offsetY = 0;

  return [x, y, offsetY];
};

const createBackground = (height, width, margin, x, y) => {
  return (g) =>
    g
      .append("rect")
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .attr("x", margin.left)
      .attr("y", margin.top);
};

const appendTitle = (titleArea, width, title) => {
  if (title) {
    titleArea
      .append("text")
      .text(title)
      .attr("x", width / 2)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("font-size", 30);
  }
};

const appendXAxisLabel = (
  plotArea,
  height,
  width,
  margin,
  x,
  y,
  xAxisLabel,
  offsetY
) => {
  if (xAxisLabel) {
    plotArea
      .append("g")
      .attr(
        "transform",
        `translate(0,${height - margin.bottom - offsetY + 10})`
      )
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.bottom - 4)
      .attr("fill", "currentColor")
      .attr("text-anchor", "middle")
      .attr("font-size", 15)
      .text(xAxisLabel);
  }
};

const appendYAxisLabel = (
  plotArea,
  height,
  width,
  margin,
  x,
  y,
  yAxisLabel,
  yOffset
) => {
  if (yAxisLabel) {
    plotArea
      .append("g")
      .attr("transform", `translate(10,10)`)
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 10 + yOffset)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .attr("transform", "rotate(-90)")
      .attr("font-size", 15)
      .text(yAxisLabel);
  }
};

const transformData = (data, categoryField, dataField) => {
  let newData = [];

  if (categoryField === "__NO__CATEGORY__") {
    for (let row of data) {
      if (row[dataField]) {
        let offset = 0;
        for (let r of newData) {
          if (Math.abs(r.x - row[dataField]) < 0.05) {
            offset += 1;
          }
        }
        newData.push({
          y: 0,
          x: parseFloat(row[dataField]),
          yOffset: offset,
        });
      }
    }
    return newData;
  }

  for (let row of data) {
    if (row[categoryField] && row[dataField]) {
      let offset = 0;
      for (let r of newData) {
        if (r.y === row[categoryField]) {
          if (Math.abs(r.x - row[dataField]) < 0.05) {
            offset += 1;
          }
        }
      }
      newData.push({
        y: row[categoryField],
        x: parseFloat(row[dataField]),
        yOffset: offset,
      });
    }
  }
  return newData;
};

const Plot = (props) => {
  const { rowData, meta } = props;

  useEffect(() => {
    const svg = d3.select("#area");
    const height = 300;
    const width = 900;
    const margin = { top: 25, right: 280, bottom: 35, left: 80 };
    const {
      title,
      xAxisLabel,
      yAxisLabel,
      dataField,
      categoryField,
      lsl,
      usl,
      classicDesign,
      includeMarkers,
      displayStatisticalPanels,
      displayMean,
      displayMedian,
      displayMode,
    } = meta;
    const targetMean = parseFloat(meta.targetMean, 10);
    let radius = 2;

    let newRowData = transformData(rowData, categoryField, dataField);

    if (newRowData.length < 200) {
      radius = 3;
    }

    if (newRowData.length < 50) {
      radius = 5;
    }
    const values = newRowData.map((r) => r.x);
    const statistics = computeStatistics(values);

    let xValues = newRowData.map((d) => d.x);
    if (includeMarkers) {
      xValues.push(parseFloat(usl, 10));
      xValues.push(parseFloat(lsl, 10));
      xValues.push(parseFloat(targetMean, 10));
    }

    let [xMin, xMax] = d3.extent(xValues);
    const xDiff = xMax - xMin;
    xMin = xMin - 0.02 * xDiff;
    xMax += 0.02 * xDiff;

    const [x, y, offsetY] = createXY(height, width, margin, newRowData, [
      xMin,
      xMax,
    ]);

    const grid = (g) =>
      g.attr("stroke", "currentColor").call((g) =>
        g
          .append("g")
          .selectAll("line")
          .data(newRowData.map((d) => d.y))
          .join("line")
          .attr("y1", (d) => 0.5 + y(d))
          .attr("y2", (d) => 0.5 + y(d))
          .attr("x1", margin.left)
          .attr("x2", width - margin.right)
      );
    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call((g) => g.select(".domain").remove());
    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(width / 80))
        .call((g) => g.select(".domain").remove());
    const background = createBackground(height, width, margin, x, y);
    const addLine = (base, name, color, xValue) => {
      base
        .append("line")
        .attr("x1", x(xValue))
        .attr("x2", x(xValue))
        .attr("y1", -15)
        .attr("y2", height - margin.bottom)
        .attr("stroke-width", 1)
        .attr("stroke", color);
      base
        .append("text")
        .attr("text-anchor", "end")
        .attr("y", x(xValue) - 3)
        .attr("x", 15)
        .attr("transform", "rotate(-90)")
        .attr("font-size", 15)
        .attr("fill", color)
        .text(name);
    };

    svg.selectAll("*").remove();

    handleBackground(svg, classicDesign);

    const plotArea = svg.append("g");
    const plotAreaYOffset = 70;
    plotArea.attr("transform", `translate(0, ${plotAreaYOffset})`);

    plotArea.append("g").call(background);
    plotArea.append("g").call(xAxis);
    if (categoryField !== "__NO__CATEGORY__") {
      plotArea.append("g").call(yAxis);
    }
    plotArea.append("g").call(grid);

    plotArea
      .append("g")
      .attr("stroke-width", 1.5)
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .selectAll("path")
      .data(newRowData)
      .join("circle")
      .attr("r", radius)
      .attr(
        "transform",
        (d) =>
          `translate(${x(d.x)},${y(d.y) - d.yOffset * (radius * 2 + 1) + y.bandwidth() - 10
          })`
      )
      .attr("fill", (d) => "red");

    appendXAxisLabel(
      plotArea,
      height,
      width - margin.right + margin.left,
      margin,
      x,
      y,
      xAxisLabel,
      offsetY
    );
    if (yAxisLabel !== "__NO__CATEGORY__") {
      appendYAxisLabel(
        plotArea,
        height,
        width,
        margin,
        x,
        y,
        yAxisLabel,
        categoryField === "__NO__CATEGORY__" ? 40 : 0
      );
    }

    const markers = plotArea.append("g");

    if (displayMode) {
      addLine(markers, "mode", "darkgreen", statistics.mode);
    }
    if (displayMedian) {
      addLine(markers, "median", "purple", statistics.median);
    }
    if (displayMean) {
      addLine(markers, "mean", "brown", statistics.mean);
    }
    if (lsl !== "") {
      addLine(markers, "lsl", "red", lsl);
    }
    if (targetMean !== "") {
      addLine(markers, "Target Mean", "red", targetMean);
    }
    if (usl !== "") {
      addLine(markers, "usl", "red", usl);
    }

    appendTitle(
      plotArea.append("g"),
      width - margin.right + margin.left,
      title
    );

    if (displayStatisticalPanels) {
      const ppkCpkWidth = margin.right - 100;

      const statArea = svg
        .append("g")
        .attr(
          "transform",
          `translate(${width - margin.right + 10}, ${plotAreaYOffset})`
        );
      const AI_Panel = svg
        .append("g")
        .attr(
          "transform",
          `translate(${width - margin.right + 10}, ${plotAreaYOffset})`
        );
      const titles = statArea
        .append("g")
        .attr("text-anchor", "start")
        .attr("font-size", 15)
        .attr("font-weight", "bold");
      titles
        .append("text")
        .text(`${targetMean?.toFixed(2)}`)
        .attr("x", margin.left + 55)
        .attr("y", -20)
        .attr("font-size", 20)
        .attr("stroke", "lightblue")
        .attr("text-decoration", "underline")
        .attr("stroke-width", 0.6);
      titles
        .append("text")
        .text("Target Mean:")
        .attr("x", margin.left + 20)
        .attr("y", -40)
        .attr("font-size", 20)

        .attr("stroke", "red")
        .attr("stroke-width", 0.6);
      let ppkBadCount = 0;
      if (categoryField !== "__NO__CATEGORY__") {
        statArea
          .append("rect")
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("width", ppkCpkWidth + 50)
          .attr("height", height - margin.top - margin.bottom + 15)
          .attr("x", margin.left - 60)
          .attr("y", margin.top - 10);

        if (usl || lsl) {
          titles.append("text").text("PpK").attr("x", margin.left).attr("y", 5);
        }

        titles
          .append("text")
          .text("Size")
          .attr("x", margin.left - 50)
          .attr("y", 5)
          .attr("fill", "purple");

        titles
          .append("text")
          .text("Avg.")
          .attr("x", margin.left + 60)
          .attr("y", 5);

        titles
          .append("text")
          .text("Std.")
          .attr("x", margin.left + 120)
          .attr("y", 5);

        const statDataArea = statArea
          .append("g")
          // .attr("transform", "translate(0, 20)")
          .attr("text-anchor", "end")
          .attr("font-size", 15)
          .attr("font-weight", "normal");

        for (let categoryValue of y.domain()) {
          let currentData = rowData
            .filter((row) => row[categoryField] === categoryValue)
            .map((row) => parseFloat(row[dataField], 10));
          const mean = d3.mean(currentData);
          const std = d3.deviation(currentData);
          if (usl || lsl) {
            let cpk = computePPK(mean, std, usl, lsl);
            statDataArea
              .append("text")
              .text(currentData.length)
              .attr("x", margin.left - 30)
              .attr("y", y(categoryValue) + y.bandwidth() / 2 + 2)
              .attr("fill", "blue")
              .attr("stroke", "blue");

            statDataArea
              .append("text")
              .text(cpk?.toFixed(2))
              .attr("x", margin.left + 30)
              .attr("y", y(categoryValue) + y.bandwidth() / 2 + 2)
              .attr("stroke", cpkToColor(cpk));
            if (cpkToColor(cpk) === "red")
              ppkBadCount++;
          }
          statDataArea
            .append("text")
            .text(mean?.toFixed(2))
            .attr("x", margin.left + 90)
            .attr("y", y(categoryValue) + y.bandwidth() / 2 + 2);
          statDataArea
            .append("text")
            .text(std?.toFixed(2))
            .attr("x", margin.left + 150)
            .attr("y", y(categoryValue) + y.bandwidth() / 2 + 2)
            .attr("stroke", std > 1 ? "red" : "darkgreen");
        }
      }
      //if (categoryField !== "__NO__CATEGORY__") {
      AI_Panel
        .append("rect")
        .attr("fill", "white")
        .attr("stroke", "black")
        .attr("width", ppkCpkWidth + 60)
        .attr("height", height - margin.top - margin.bottom + 230)
        .attr("x", margin.left + 200)
        .attr("y", margin.top - 80)
        .attr("fill", "lightyellow");
      AI_Panel
        .append("text")
        .text("AI Panel")
        .attr("x", margin.left + 250)
        .attr("y", 25)
        .attr("font-size", 40)
        .attr("text-decoration", "underline");
      AI_Panel
        .append("text")
        .text(`${ppkBadCount} of ${y.domain().length}`)
        .attr("x", margin.left + 290 - Math.pow(`${ppkBadCount}`.length, 3))
        .attr("y", 95)
        .attr("font-size", 25)
        .attr("fill", "red")
        .attr("font-weight", "bold");
      AI_Panel
        .append("text")
        .text("categories")
        .attr("x", margin.left + 265)
        .attr("y", 125)
        .attr("fill", "red")
        .attr("font-size", 25);
      AI_Panel
        .append("text")
        .text("have Poor")
        .attr("x", margin.left + 268)
        .attr("y", 155)
        .attr("font-size", 25)
        .attr("fill", "red")
        .attr("text-decoration", "underline");
      AI_Panel
        .append("text")
        .text("PPK Results")
        .attr("x", margin.left + 258)
        .attr("y", 190)
        .attr("font-size", 25)
        .attr("fill", "red")
        .attr("text-decoration", "underline");
      AI_Panel
        .append("text")
        .text("Investigate")
        .attr("x", margin.left + 255)
        .attr("y", 220)
        .attr("font-size", 25)
        .attr("fill", "red")
        .attr("font-weight", "bold");
      AI_Panel
        .append("text")
        .text(`these ${ppkBadCount}`)
        .attr("x", margin.left + 276 - Math.pow(`${ppkBadCount}`.length, 2))
        .attr("y", 250)
        .attr("font-size", 25)
        .attr("fill", "red")
        .attr("font-weight", "bold");
      AI_Panel
        .append("text")
        .text("before")
        .attr("x", margin.left + 280)
        .attr("y", 280)
        .attr("font-size", 25)
        .attr("fill", "red")
        .attr("font-weight", "bold");
      AI_Panel
        .append("text")
        .text("making other")
        .attr("x", margin.left + 250)
        .attr("y", 310)
        .attr("fill", "red")
        .attr("font-size", 25);
      AI_Panel
        .append("text")
        .text("decisions")
        .attr("x", margin.left + 270)
        .attr("y", 340)
        .attr("fill", "red")
        .attr("font-size", 25);
      //}

      const statHeight = 80;

      const ppk = computePPK(statistics.mean, statistics.std, usl, lsl);

      if (ppk) {
        const ppkArea = svg
          .append("g")
          .attr(
            "transform",
            `translate(${width - margin.right + 10}, ${height + plotAreaYOffset + 25
            })`
          )
          .attr("text-anchor", "start")
          .attr("font-size", 15)
          .attr("font-weight", "normal");

        ppkArea
          .append("rect")
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("width", ppkCpkWidth)
          .attr("height", statHeight / 2)
          .attr("x", margin.left - 10)
          .attr("y", margin.top - offsetY - 20);
        const ppKtitles = ppkArea
          .append("g")
          .attr("text-anchor", "start")
          .attr("font-size", 15)
          .attr("font-weight", "bold");

        ppKtitles
          .append("text")
          .text("PpK")
          .attr("x", margin.left)
          .attr("y", -10);
        ppKtitles
          .append("text")
          .text("Avg.")
          .attr("x", margin.left + 60)
          .attr("y", -10);

        ppKtitles
          .append("text")
          .text("Std.")
          .attr("x", margin.left + 120)
          .attr("y", -10);

        const ppkValues = ppkArea.append("g").attr("font-weight", "normal");
        ppkValues
          .append("text")
          .text(ppk?.toFixed(2))
          .attr("x", margin.left + 0)
          .attr("y", 30)
          .attr("stroke", cpkToColor(ppk));
        ppkValues
          .append("text")
          .text(statistics.mean?.toFixed(2))
          .attr("x", margin.left + 60)
          .attr("y", 30);
        ppkValues
          .append("text")
          .text(statistics.std?.toFixed(2))
          .attr("x", margin.left + 120)
          .attr("y", 30)
          .attr("stroke", stdToColor(statistics.std));

        ppkValues
          .append("text")
          .text("Total Data Set")
          .attr("x", margin.left + 75)
          .attr("y", 70)
          .attr("font-size", 20)
          .attr("stroke", "black")
          .attr("text-anchor", "middle");
      }

      const overallStatArea = svg
        .append("g")
        .attr("transform", `translate(0,${height + 100})`);

      overallStatArea
        .append("g")
        .append("rect")
        .attr("x", margin.left - 20)
        .attr("y", 0)
        .attr("width", width - margin.right - margin.left + 40)
        .attr("height", statHeight)
        .attr("fill", "white")
        .attr("stroke", "black");
      overallStatArea
        .append("g")
        .append("text")
        .text("Statistics")
        .attr("text-anchor", "middle")
        .attr("font-size", 20)
        .attr("x", margin.left + (width - margin.right - margin.left) / 2)
        .attr("y", 22)
        .attr("stroke", "darkblue");

      const statisticsFields = [
        "var",
        "count",
        "mean",
        "std",
        "min",
        "median",
        "max",
        "mode",
        "modeCount",
        //"pValue",
      ];
      const statisticsTitles = {
        var: "Variable",
        count: "Count",
        mean: "Mean",
        std: "Std.",
        min: "Minimum",
        median: "Median",
        max: "Maximum",
        mode: "Mode",
        modeCount: "Count",
        //pValue: "p-Value",
      };
      const statisticsTitlesTop = {
        var: "",
        count: "Total",
        mean: "",
        std: ".",
        min: "",
        median: "",
        max: "",
        mode: "",
        modeCount: "Mode",
        //pValue: "",
      };
      const statisticsColors = {
        var: "black",
        count: "black",
        mean: "brown",
        std: "black",
        min: "darkblue",
        median: "purple",
        max: "darkblue",
        mode: "darkgreen",
        modeCount: "black",
        //pValue: "black",
      };
      statistics["var"] = xAxisLabel;
      const overallStatX = d3
        .scaleBand()
        .domain(statisticsFields)
        .range([margin.left, width - margin.right]);

      const table = overallStatArea
        .append("g")
        .attr("transform", `translate(40, 40)`);

      table
        .append("g")
        .selectAll("text")
        .data(statisticsFields)
        .join("text")
        .attr("x", (d) => overallStatX(d))
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("stroke", (d) => statisticsColors[d])
        .attr("stroke-width", (d) =>
          statisticsColors[d] === "black" ? 0.5 : 0.8
        )
        .attr("text-decoration", (d) => {
          if (d === "min" || d === "max") {
            return "underline";
          }
          return "";
        })
        .text((d) => statisticsTitles[d]);

      table
        .append("g")
        .selectAll("text")
        .data(statisticsFields)
        .join("text")
        .attr("x", (d) => overallStatX(d))
        .attr("y", -20)

        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("stroke", (d) => statisticsColors[d])
        .attr("stroke-width", (d) =>
          statisticsColors[d] === "black" ? 0.5 : 0.8
        )
        .text((d) => statisticsTitlesTop[d]);

      const formatStatistics = (d, val) => {
        if (d === "var" || d === "count") {
          return val;
        }
        return val?.toFixed(2);
      };
      table
        .append("g")
        .selectAll("text")
        .data(statisticsFields)
        .join("text")
        .attr("x", (d) => overallStatX(d))
        .attr("y", 20)
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("stroke-width", (d) =>
          statisticsColors[d] === "black" ? 0.5 : 0.8
        )
        .attr("stroke", (d) => statisticsColors[d])

        .text((d) => formatStatistics(d, statistics[d]));
    }
    // if (document.getElementById("area")) {
    //   const svgData =
    //     '<svg viewBox="0 0 700 500" xmlns="http://www.w3.org/2000/svg">' +
    //     document.getElementById("area").innerHTML +
    //     "</svg>";
    //   setImgString("data:image/svg+xml;base64," + btoa(svgData));
    // }
  }, [rowData, meta]);

  return (
    <svg
      id="area"
      height={500}
      width={1160}
    // style={{ backgroundColor: "lightgray" }}
    ></svg>
  );
};

export default Plot;
