import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";

const DataUpdateDispatch = React.createContext();

const MyTableCell = (props) => {
  const { value, field, rowId, isNumber } = props;
  const [editing, setEditing] = useState(false);
  const [current, setCurrent] = useState(value);
  const dispatch = useContext(DataUpdateDispatch);
  const textFieldRef = useCallback((node) => {
    let inputs = node?.getElementsByTagName("input");
    if (inputs) {
      inputs[0]?.focus();
    }
  }, []);

  useEffect(() => {
    setCurrent(value);
  }, [setCurrent, value]);

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      if (document.activeElement) {
        const elements = [...document.querySelectorAll("[tabindex]")];
        let active = document.activeElement;
        let index = elements.indexOf(active);
        while (index === -1) {
          active = active.parentElement;
          index = elements.indexOf(active);
        }
        index++;
        if (index === elements.length) {
          index = 0;
        }
        elements[index].focus();
      }
      dispatch({ rowId: rowId, field: field, value: current });

      setEditing(false);
      event.preventDefault();
    }
  };

  if (editing) {
    const fieldType = isNumber ? "number" : "text";
    return (
      <TableCell tabIndex="0">
        <Input
          value={current}
          type={fieldType}
          fullWidth
          onBlur={() => {
            setEditing(false);
            dispatch({ rowId: rowId, field: field, value: current });
          }}
          onChange={(e) => {
            setCurrent(e.target.value);
          }}
          onKeyDown={handleEnter}
          size="small"
          ref={textFieldRef}
          inputProps={{ sx: { padding: 0, border: 0 } }}
          sx={{ padding: 0, margin: 0, border: 0 }}
        />
      </TableCell>
    );
  }

  return (
    <TableCell
      tabIndex="0"
      onClick={() => {
        setEditing(true);
      }}
      onFocus={() => {
        setEditing(true);
      }}
    >
      {value}
    </TableCell>
  );
};

const MyTableRow = (columns, row, idx, dataFields) => {
  let backgroundColor = "#FAE7D4";
  if (idx % 2) {
    backgroundColor = "#FBF8E9";
  }
  return (
    <TableRow key={idx} sx={{ background: backgroundColor, height: "40px" }}>
      {columns.map((field) => (
        <MyTableCell
          key={field}
          value={row[field]}
          rowId={idx}
          field={field}
          isNumber={dataFields.indexOf(field) > -1}
        />
      ))}
    </TableRow>
  );
};

const copyData = (data) => {
  let result = [...data];
  result.columns = data.columns;
  return result;
};

const reducer = (state, action) => {
  if (action.type === "new_data") {
    return [...action.data];
  }
  state[action.rowId][action.field] = action.value;
  return [...state];
};

const EditableTable = (props) => {
  const { data, setData, dataFields } = props;
  const columns = data.columns;
  const width = 100 / columns.length;

  const [tableData, dispatch] = useReducer(reducer, copyData(data));

  useEffect(() => {
    tableData.columns = columns;
    setData(tableData);
  }, [setData, tableData, columns]);

  useEffect(() => {
    dispatch({
      type: "new_data",
      data: data,
    });
  }, [data, dispatch]);

  return (
    <DataUpdateDispatch.Provider value={dispatch}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader className="alpine" size="small">
          <TableHead>
            <TableRow sx={{ background: "lightgray" }}>
              {columns.map((name) => (
                <TableCell
                  sx={{ background: "lightgray" }}
                  key={name}
                  width={width + "%"}
                >
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, idx) =>
              MyTableRow(columns, row, idx, dataFields)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DataUpdateDispatch.Provider>
  );
};

export default EditableTable;
