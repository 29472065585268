import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const Ratings = () => {
  return (
    <Table size="small" sx={{ marginTop: "85px" }}>
      <TableHead sx={{ background: "gold" }}>
        <TableRow>
          <TableCell align="center" sx={{ fontWeight: "600" }}>
            PpK Ratings
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ background: "white", fontWeight: "600" }}>
        <TableRow>
          <TableCell sx={{ color: "red", fontWeight: "600" }} align="center">
            &lt; 0.0
            <br /> Contact Management
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ color: "red", fontWeight: "600" }} align="center">
            &lt; 1.0
            <br /> Poor
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{ color: "darkorange", fontWeight: "600" }}
            align="center"
          >
            &lt; 1.33
            <br /> Caution
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{ color: "darkgreen", fontWeight: "600" }}
            align="center"
          >
            &gt; 1.33
            <br /> Good
          </TableCell>
        </TableRow>
      </TableBody>
      <TableHead sx={{ background: "gold" }}>
        <TableRow>
          <TableCell align="center" sx={{ fontWeight: "600" }}>
            Std Deviation Ratings
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ background: "white", fontWeight: "600" }}>
        <TableRow>
          <TableCell sx={{ color: "red", fontWeight: "600" }} align="center">
            &gt; 1
            <br /> Poor
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{ color: "darkgreen", fontWeight: "600" }}
            align="center"
          >
            &lt; 1
            <br /> Good
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Ratings;
