import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState, useCallback } from "react";

const MyTableCell = (props) => {
  const { value, setValue } = props;
  const [editing, setEditing] = useState(false);
  const [current, setCurrent] = useState(value);
  const textFieldRef = useCallback((node) => {
    console.log("GI");
    console.log(node);
    let inputs = node?.getElementsByTagName("input");
    if (inputs) {
      inputs[0]?.focus();
    }
  }, []);

  if (editing) {
    return (
      <TableCell>
        <TextField
          value={current}
          fullWidth
          onBlur={() => {
            setValue(current);
            setEditing(false);
          }}
          onChange={(e) => {
            setCurrent(e.target.value);
          }}
          size="small"
          ref={textFieldRef}
        />
      </TableCell>
    );
  }

  return (
    <TableCell
      onClick={() => {
        setEditing(true);
      }}
    >
      {value}
    </TableCell>
  );
};

const MyTableRow = (columns, row, idx, data, setData) => {
  let backgroundColor = "#FAE7D4";
  if (idx % 2) {
    backgroundColor = "#FBF8E9";
  }
  return (
    <TableRow key={idx} sx={{ background: backgroundColor, height: "4ex" }}>
      {columns.map((field) => (
        <MyTableCell
          key={field}
          value={row[field]}
          setValue={(val) => {
            data[idx][field] = val;
            let newData = [...data];
            newData.columns = data.columns;
            setData(newData);
          }}
        />
      ))}
    </TableRow>
  );
};

const MyTable = (props) => {
  const { data, setData } = props;
  const columns = data.columns;

  console.log(data);

  const addRow = () => {
    let newData = [...data];
    newData.push(
      columns.reduce((d, f) => {
        d[f] = "";
        return d;
      }, {})
    );
    newData.columns = columns;
    // newData.length += 1;
    setData(newData);
  };

  return (
    <TableContainer sx={{ maxHeight: 400 }}>
      <Table stickyHeader className="alpine" size="small">
        <TableHead>
          <TableRow sx={{ background: "#FACFCD" }}>
            {columns.map((name) => (
              <TableCell sx={{ background: "#FACFCD" }} key={name}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => MyTableRow(columns, row, idx, data, setData))}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Button fullWidth onClick={addRow}>
                Add row
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter> */}
      </Table>
    </TableContainer>
  );
};

export default MyTable;
