import React from "react";
import Highcharts from "highcharts/highstock";
import pareto from "highcharts/modules/pareto";
import HighchartsReact from "highcharts-react-official";

function standardDeviation(numArray) {
  const mean = numArray.reduce((s, n) => s + n) / numArray.length;
  const variance = numArray.reduce((s, n) => s + (n - mean) ** 2, 0) / (numArray.length - 1);
  return Math.sqrt(variance);
}
function calculateAverage(arr) {
  if (arr.length === 0) {
    return 0; // Return 0 if the array is empty
  }

  var sum = 0;
  for (var i = 0; i < arr.length; i++) {
    sum += arr[i]; // Add each element to the sum
  }

  var average = sum / arr.length; // Divide the sum by the number of elements

  return average;
}
export default function TimeSeriesChart(props) {
  pareto(Highcharts);

  console.log(props.series);

  let i = 0;
  var categories = [];
  var series = [];
  var series_data = [];
  for (i = 0; i < props.series.length; i++) {
    categories.push(props.series[i][0]);
    series.push(parseFloat(props.series[i][1]));
  }

  //console.log(props.lsl, props.targetMean, props.usl);

  if (series.length === 0) return;

  const standard_derivation = standardDeviation(series);
  const average = calculateAverage(series);

  for (i = 0; i < props.series.length; i++) {
    var series_value = parseFloat(props.series[i][1]);
    if (series_value >= average - 3 * standard_derivation && series_value <= average + 3 * standard_derivation)
      series_data.push({ 'y': parseFloat(props.series[i][1]), 'color': 'blue' });
    else
      series_data.push({ 'y': parseFloat(props.series[i][1]), 'color': 'red' });
  }

  console.log(props.targetMean);
  
  const options = {
    chart: {
      type: 'line',
      // backgroundColor: props.classicDesign ? 'lightgray' : '#FFF'
    },
    title: {
      text: props.meta.title
    },
    credits: {
      enabled: false // Disables the credits watermark
    },
    subtitle: {
      //text: 'Average = ' + average.toFixed(2),
      text: `<span style="color: green;">Average = ${average.toFixed(2)}</span><br><span style="color: blue;">Total Count = ${categories.length}</span><br><span style="color: red;">Target = ${props.targetMean*1.0.toFixed(2)}</span>`,
      style: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: 'green',
      }
    },
    xAxis: {
      categories: categories,
      title: {
        text: props.meta.xAxisLabel,
        style: {
          fontWeight: 'bold',
          fontSize: '18px',
          color: 'black',
        },
      },
      labels: {
        formatter: function () {
          if (isNaN(parseFloat(this.value)) || props.meta.xAxisLabel === "No X Data Defined" || props.meta.xAxisLabel)
            return this.value;
          else
            return Highcharts.numberFormat(this.value, 3);
        }
      }
    },
    yAxis: {
      // min: Math.min(...series) > (average - 3 * standard_derivation - 1) ? (average - 3 * standard_derivation - 1) : Math.min(...series),
      // max: Math.max(...series) > (average + 3 * standard_derivation + 1) ? Math.max(...series) : (average + 3 * standard_derivation + 1),
      min: Math.min(...series) > (average - 3 * standard_derivation) ? (average - 3 * standard_derivation) : Math.min(...series),
      max: Math.max(...series) > (average + 3 * standard_derivation) ? Math.max(...series) : (average + 3 * standard_derivation),
      title: {
        text: props.meta.yAxisLabel,
        style: {
          fontWeight: 'bold',
          fontSize: '18px',
          color: 'black',
        }
      },
      plotLines: [{
        value: average - 3 * standard_derivation,
        color: 'blue',
        width: 2,
        zIndex: 10,
        dashStyle: 'dash'
      },
      {
        value: average,
        color: 'green',
        width: 2,
        zIndex: 10,
        label: {
          //          text: 'Target',
          align: 'center',
          style: {
            fontWeight: 'bold',
            fontSize: '18px',
            color: 'green',
          },
        }
      },
      {
        value: average + 3 * standard_derivation,
        color: 'blue',
        width: 2,
        zIndex: 10,
        dashStyle: 'dash'
        // label: {
        //   text: 'Upper',
        //   align: 'right',
        //   style: {
        //     fontWeight: 'bold',
        //     fontSize: '18px',
        //     color: 'red',
        //   },
        // }
      }],
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true,
      },
    },
    series: [{
      name: 'Time Series Data',
      data: series_data,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y.toFixed(3);
        }
      }
    }],
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
