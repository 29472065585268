import React, { useEffect } from "react";
import * as d3 from "d3";

import { handleBackground } from "./plotHelper";

const createBackground = (height, width, margin, x, y) => {
  return (g) =>
    g
      .append("rect")
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .attr("x", margin.left)
      .attr("y", margin.top);
};

const appendTitle = (titleArea, width, title) => {
  if (title) {
    titleArea
      .append("text")
      .text(title)
      .attr("x", width / 2)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("font-size", 30);
  }
};

const appendXAxisLabel = (
  plotArea,
  height,
  width,
  margin,
  x,
  y,
  xAxisLabel
) => {
  if (xAxisLabel) {
    plotArea
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom + 10})`)
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.bottom - 4)
      .attr("fill", "currentColor")
      .attr("text-anchor", "middle")
      .attr("font-size", 15)
      .text(xAxisLabel);
  }
};

const appendYAxisLabel = (
  plotArea,
  height,
  width,
  margin,
  x,
  y,
  yAxisLabel,
  yOffset
) => {
  if (yAxisLabel) {
    plotArea
      .append("g")
      .attr("transform", `translate(10,10)`)
      .append("text")
      .attr("x", -height / 2)
      .attr("y", yOffset - 15)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .attr("transform", "rotate(-90)")
      .attr("font-size", 15)
      .text(yAxisLabel);
  }
};

const transformData = (data, categoryField, dataField) => {
  let newData = [];

  if (categoryField === "__NO__CATEGORY__") {
    for (let row of data) {
      if (row[dataField]) {
        newData.push({
          x: 0,
          y: parseFloat(row[dataField]),
        });
      }
    }
    return newData;
  }

  for (let row of data) {
    if (row[categoryField] && row[dataField]) {
      newData.push({
        x: row[categoryField],
        y: parseFloat(row[dataField]),
      });
    }
  }
  return newData;
};

const BoxPlot = (props) => {
  const { rowData, meta } = props;

  useEffect(() => {
    const svg = d3.select("#area");
    const height = 400;
    const width = 1000;
    const margin = { top: 25, right: 280, bottom: 30, left: 80 };
    const plotAreaYOffset = 70;
    const {
      title,
      xAxisLabel,
      yAxisLabel,
      dataField,
      categoryField,
      lsl,
      usl,
      classicDesign,
      includeMarkers,
      meanSymbol,
      meanConnected,
      showOutliers,
    } = meta;

    if (meta.xAxisLabel === "No X Data Defined")
      return;
    const targetMean = parseFloat(meta.targetMean, 10);
    let radius = 2;

    let newRowData = transformData(rowData, categoryField, dataField);

    const xValues = newRowData.map((d) => d.x);

    const x = d3
      .scaleBand()
      .align(1)
      .domain(xValues)
      .range([margin.left, width - margin.right]);

    const plotData = [];

    for (let xx of x.domain()) {
      let yData = newRowData.filter((d) => d.x === xx).map((d) => d.y);
      yData = yData.sort();

      const median = d3.median(yData);
      let medianIndex = d3.medianIndex(yData);
      if (yData[medianIndex + 1] === median) {
        medianIndex += 1;
      }
      // const q1 = d3.quantile(yData, 0.25);
      // const q3 = d3.quantile(yData, 0.75);
      // These do not agree with minitab
      const q1 = d3.median(yData.slice(0, medianIndex));
      const q3 = d3.median(yData.slice(medianIndex + 1, yData.length));

      const fenceMax = q3 + 1.5 * (q3 - q1);
      const fenceMin = q1 - 1.5 * (q3 - q1);

      let outliers = yData.filter((y) => y < fenceMin || y > fenceMax);

      let mean = d3.mean(yData);

      yData = yData.filter((y) => y >= fenceMin && y <= fenceMax);

      const min = d3.min(yData);
      const max = d3.max(yData);
      if (!showOutliers) {
        mean = d3.mean(yData);
      }

      plotData.push({
        x: xx,
        q1,
        q3,
        min,
        max,
        mean,
        median,
        outliers,
      });
    }

    let yValues = [];

    for (let p of plotData) {
      yValues.push(p.min);
      yValues.push(p.max);
      if (showOutliers) {
        yValues = yValues.concat(p.outliers);
      }
    }

    if (includeMarkers) {
      yValues.push(parseFloat(usl, 10));
      yValues.push(parseFloat(lsl, 10));
      yValues.push(parseFloat(targetMean, 10));
    }

    let [yMin, yMax] = d3.extent(yValues);
    const yDiff = yMax - yMin;
    yMin = yMin - 0.02 * yDiff;
    yMax += 0.02 * yDiff;
    const y = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call((g) => g.select(".domain").remove());
    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(width / 80))
        .call((g) => g.select(".domain").remove());
    const background = createBackground(height, width, margin, x, y);
    const addLine = (base, name, color, xValue) => {
      base
        .append("line")
        .attr("y1", y(xValue))
        .attr("y2", y(xValue))
        .attr("x1", margin.left)
        .attr("x2", width - margin.right + 30)
        .attr("stroke-width", 1)
        .attr("stroke", color)
        .attr("stroke-dasharray", 4);
      base
        .append("text")
        .attr("text-anchor", "end")
        .attr("y", y(xValue) - 3)
        .attr("x", width - margin.right + 20)
        // .attr("transform", "rotate(-90)")
        .attr("font-size", 15)
        .attr("fill", color)
        .text(xValue);
    };

    svg.selectAll("*").remove();

    handleBackground(svg, classicDesign);

    const plotArea = svg.append("g");
    plotArea.attr("transform", `translate(0, ${plotAreaYOffset})`);

    plotArea.append("g").call(background);
    plotArea.append("g").call(yAxis);
    if (categoryField !== "__NO__CATEGORY__") {
      plotArea.append("g").call(xAxis);
    }

    plotArea
      .append("g")
      .attr("stroke-width", 1.5)
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .selectAll("path")
      .data(newRowData)
      .join("circle")
      .attr("r", radius)
      .attr(
        "transform",
        (d) =>
          `translate(${x(d.x)},${y(d.y) - d.yOffset * (radius * 2 + 1) + x.bandwidth() - 10
          })`
      )
      .attr("fill", (d) => "blue");

    appendXAxisLabel(
      plotArea,
      height,
      width - margin.right + margin.left,
      margin,
      x,
      y,
      xAxisLabel
    );
    if (yAxisLabel !== "__NO__CATEGORY__") {
      appendYAxisLabel(plotArea, height, width, margin, x, y, yAxisLabel, 40);
    }

    const markers = plotArea.append("g");

    if (lsl !== "") {
      addLine(markers, "lsl", "red", lsl);
    }
    if (targetMean !== "") {
      addLine(markers, "Target Mean", "red", targetMean);
    }
    if (usl !== "") {
      addLine(markers, "usl", "red", usl);
    }

    appendTitle(
      plotArea.append("g"),
      width - margin.right + margin.left,
      title
    );

    plotArea
      .append("g")
      .selectAll("rect")
      .data(plotData)
      .join("rect")
      .attr("x", (d) => x(d.x) - 20 + x.bandwidth() / 2)
      .attr("height", (d) => y(d.q1) - y(d.q3))
      .attr("width", 40)
      .attr("y", (d) => y(d.q3))
      .attr("fill", "lightblue")
      .attr("stroke", "black");
    plotArea
      .append("g")
      .selectAll("line")
      .data(plotData)
      .join("line")
      .attr("x1", (d) => x(d.x) + x.bandwidth() / 2 + 20)
      .attr("x2", (d) => x(d.x) + x.bandwidth() / 2 - 20)
      .attr("y1", (d) => y(d.median))
      .attr("y2", (d) => y(d.median))
      .attr("stroke", "black");
    plotArea
      .append("g")
      .selectAll("line")
      .data(plotData)
      .join("line")
      .attr("x1", (d) => x(d.x) + x.bandwidth() / 2)
      .attr("x2", (d) => x(d.x) + x.bandwidth() / 2)
      .attr("y1", (d) => y(d.q3))
      .attr("y2", (d) => y(d.max))
      .attr("stroke", "black");
    plotArea
      .append("g")
      .selectAll("line")
      .data(plotData)
      .join("line")
      .attr("x1", (d) => x(d.x) + x.bandwidth() / 2)
      .attr("x2", (d) => x(d.x) + x.bandwidth() / 2)
      .attr("y1", (d) => y(d.q1))
      .attr("y2", (d) => y(d.min))
      .attr("stroke", "black");

    if (meanSymbol) {
      const meanArea = plotArea.append("g");
      meanArea
        .append("g")
        .selectAll("circle")
        .data(plotData)
        .join("circle")
        .attr("r", 5)
        .attr("cx", (d) => x(d.x) + x.bandwidth() / 2)
        .attr("cy", (d) => y(d.mean))
        .attr("stroke", "black")
        .attr("fill-opacity", 0);
      meanArea
        .append("g")
        .selectAll("line")
        .data(plotData)
        .join("line")
        .attr("x1", (d) => x(d.x) + x.bandwidth() / 2 - 5)
        .attr("x2", (d) => x(d.x) + x.bandwidth() / 2 + 5)
        .attr("y1", (d) => y(d.mean))
        .attr("y2", (d) => y(d.mean))
        .attr("stroke", "black");
      meanArea
        .append("g")
        .selectAll("line")
        .data(plotData)
        .join("line")
        .attr("x1", (d) => x(d.x) + x.bandwidth() / 2)
        .attr("x2", (d) => x(d.x) + x.bandwidth() / 2)
        .attr("y1", (d) => y(d.mean) + 5)
        .attr("y2", (d) => y(d.mean) - 5)
        .attr("stroke", "black");
    }

    if (showOutliers) {
      for (let pData of plotData) {
        if (pData.outliers.length > 0) {
          plotArea
            .append("g")
            .selectAll("path")
            .data(pData.outliers)
            .join("path")
            .attr("d", d3.symbol().type(d3.symbolStar).size(30))
            .attr(
              "transform",
              (d) => `translate(${x(pData.x) + x.bandwidth() / 2}, ${y(d)})`
            )
            .attr("fill", "red");
        }
      }
    }

    if (meanConnected) {
      const meanLine = d3.line();
      const lineData = plotData
        .map((d) => [x(d.x) + x.bandwidth() / 2, y(d.mean)])
        .sort((d) => d[0]);
      plotArea
        .append("path")
        .attr("d", meanLine(lineData))
        .attr("stroke", "black")
        .attr("fill-opacity", "0");
    }

    const statArea = svg
      .append("g")
      .attr(
        "transform",
        `translate(${width - margin.right}, ${plotAreaYOffset})`
      );

    statArea
      .append("rect")
      .attr("width", 240)
      .attr("height", height - margin.top - margin.bottom + 55)
      .attr("x", margin.left - 60)
      .attr("y", margin.top)
      .attr("fill", "white")
      .attr("stroke", "black");

    const statInnerArea = statArea
      .append("g")
      .attr("transform", `translate{${margin.left - 20}, ${margin.top}}`);

    statInnerArea
      .append("text")
      .text("Name")
      .attr("font-size", 14)
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("x", margin.left - 15)
      .attr("y", margin.top + 20);
    statInnerArea
      .append("text")
      .text("Q1")
      .attr("font-size", 14)
      .attr("text-anchor", "middle")
      .attr("font-weight", "normal")
      .attr("x", margin.left + 55)
      .attr("y", margin.top + 20);
    statInnerArea
      .append("text")
      .text("Median")
      .attr("font-size", 14)
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("x", margin.left + 105)
      .attr("y", margin.top + 20);
    statInnerArea
      .append("text")
      .text("Q3")
      .attr("font-size", 14)
      .attr("text-anchor", "middle")
      .attr("font-weight", "normal")
      .attr("x", margin.left + 155)
      .attr("y", margin.top + 20);

    const entryRow = (entry, statInnerArea, idx) => {
      let name = entry.x;
      if (categoryField === "__NO__CATEGORY__") {
        name = "Total";
      }
      statInnerArea
        .append("text")
        .text(name)
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("font-weight", "bold")
        .attr("x", margin.left - 15)
        .attr("y", margin.top + 70 + idx * 25);
      statInnerArea
        .append("text")
        .text(Number(entry.q1).toFixed(2))
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("x", margin.left + 55)
        .attr("y", margin.top + 70 + idx * 25);
      statInnerArea
        .append("text")
        .text(Number(entry.median).toFixed(2))
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("font-weight", "bold")
        .attr("x", margin.left + 105)
        .attr("y", margin.top + 70 + idx * 25);
      statInnerArea
        .append("text")
        .text(Number(entry.q3).toFixed(2))
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .attr("x", margin.left + 155)
        .attr("y", margin.top + 70 + idx * 25);
    };

    for (let idx in plotData) {
      let entry = plotData[idx];
      entryRow(entry, statInnerArea, idx);
    }

    if (categoryField === "__NO__CATEGORY__") {
      const outliers = plotData[0].outliers;
      const med = plotData[0].median;
      const outliersGreater = outliers.filter((x) => x > med).length;
      statInnerArea
        .append("text")
        .text(`Outliers > Median = ${outliersGreater}`)
        .attr("font-size", 14)
        .attr("text-anchor", "left")
        .attr("font-weight", "normal")
        .attr("x", margin.left + 5)
        .attr("y", margin.top + 140);

      const outliersSmaller = outliers.filter((x) => x < med).length;
      statInnerArea
        .append("text")
        .text(`Outliers < Median = ${outliersSmaller}`)
        .attr("font-size", 14)
        .attr("text-anchor", "left")
        .attr("font-weight", "normal")
        .attr("x", margin.left + 5)
        .attr("y", margin.top + 170);

      statInnerArea
        .append("text")
        .text(`Total = ${outliersSmaller + outliersGreater}`)
        .attr("font-size", 14)
        .attr("text-anchor", "left")
        .attr("font-weight", "normal")
        .attr("x", margin.left + 5)
        .attr("y", margin.top + 200);
    }
  }, [rowData, meta]);

  return (
    meta.xAxisLabel === "No X Data Defined" ? <div></div> :
      <svg
        id="area"
        height={500}
        width={1000}
        style={{ backgroundColor: "lightgray" }}
      ></svg> 
  );
};

export default BoxPlot;
