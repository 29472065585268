import React from "react";
import Highcharts from "highcharts/highstock";
import pareto from "highcharts/modules/pareto";
import HighchartsReact from "highcharts-react-official";
import { sortArraysSync } from "../helper";
import { Button } from "@mui/material";
import { useState } from "react";


export default function ParetoChart(props) {
  const [ThresholdOption, setThresholdOption] = useState(false);

  console.log(props.pareto_data);
  pareto(Highcharts);
  const [categories, series] = sortArraysSync(Object.keys(props.pareto_data), Object.values(props.pareto_data));
  const total_count = series.reduce(function (sum, value) { return sum + value; }, 0);
  var dataPoints = [];
  let i = 0;
  for (i = 0; i < series.length; i++) {
    var data = [i, series[i]];
    dataPoints.push(data);
  }

  dataPoints.sort(function (a, b) {
    return b[1] - a[1];
  });

  var totalSum = 0;
  for (i = 0; i < dataPoints.length; i++) {
    totalSum += dataPoints[i][1];
  }

  var cumulativeSum = 0;
  var cumulativePercentage = 0;
  var desiredXValue;

  for (i = 0; i < dataPoints.length; i++) {
    cumulativeSum += dataPoints[i][1];
    cumulativePercentage = (cumulativeSum / totalSum) * 100;

    if (cumulativePercentage >= 80) {
      desiredXValue = dataPoints[i][0] - (cumulativePercentage - 80) / 100.0;
      break;
    }
  }
  if (props.meta.yAxisLabel !== "__NO__CATEGORY__") {
    for (i = 0; i < series.length; i++) {
      series[i] = parseFloat(series[i].toFixed(2));
    }
  }
  console.log("Desired x value when y value is 80%: " + desiredXValue);

  var subtitleText = props.meta.yAxisLabel === "__NO__CATEGORY__" ? "Count" : props.meta.yAxisLabel;
  subtitleText = "Total " + subtitleText + " = " + total_count.toFixed(2);
  const options = {
    chart: {
      type: "pareto",
      height: 500,
      // backgroundColor: props.classicDesign ? 'lightgray' : '#FFF'
    },
    credits: {
      enabled: false // Disables the credits watermark
    },
    title: {
      text: props.meta.title
    },
    subtitle: {
      text: subtitleText,
      y: 40,
      style: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: 'blue',
      }
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      labels: {
        style: {
          fontWeight: 'bold',
          fontSize: '18px'
        },
        formatter: function () {
          const regex = /[a-zA-Z]/;
          if (isNaN(parseFloat(this.value)) || regex.test(this.value) )
            return this.value;
          else
            return Highcharts.numberFormat(this.value, 3);
        }
      },
      plotLines: [{
        value: desiredXValue,
        color: 'red',
        dashStyle: 'dash',
        width: 3 * ThresholdOption,
        zIndex: 15,
        label: {
          text: '',
          align: 'left',
          style: {
            color: 'red'
          }
        },
      }],
      startOnTick: true,
      endOnTick: true,
      tickmarkPlacement: 'on',
      title: {
        text: props.meta.xAxisLabel,
        style: {
          fontWeight: 'bold',
          fontSize: '18px',
          color: 'black',
        }
      }
    },
    yAxis: [
      {
        title: {
          text: props.meta.yAxisLabel === "__NO__CATEGORY__" ? "Count" : props.meta.yAxisLabel,
          style: {
            fontWeight: 'bold',
            fontSize: '18px',
            color: 'black',
          }
        }
      },
      {
        title: {
          text: "Percentage"
        },
        minPadding: 0,
        maxPadding: 0,
        max: 100,
        min: 0,
        opposite: true,
        labels: {
          format: "{value}%"
        },
        plotLines: [{
          value: 80,
          color: 'red',
          dashStyle: 'dash',
          width: 3 * ThresholdOption,
          zIndex: 15,
          label: {
            text: 'Threshold: 80%',
            align: 'left',
            style: {
              fontWeight: 'bold',
              fontSize: '18px',
              color: 'red',
              display: ThresholdOption ? '' : 'none'
            },
          }
        }]
      }
    ],
    series: [
      {
        type: "pareto",
        name: "Percent",
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "%"
        }
      },
      {
        name: props.meta.yAxisLabel === "__NO__CATEGORY__" ? "Count" : props.meta.yAxisLabel,
        type: "column",
        zIndex: 2,
        data: series,
        // tooltip: {
        //   valueDecimals: 3
        // }
      }
    ]
  };
  return (
    <div>
      <Button variant="contained" color="error" style={{ marginLeft: "40px" }} onClick={(event) => {
        setThresholdOption(!ThresholdOption);
      }}>Draw 80% Line</Button>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div >
  );
}
