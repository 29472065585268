import * as d3 from "d3";
import * as chisquare from "@stdlib/stats-base-dists-chisquare";

// Compute p-Value according to Jarque–Bera test
const computePValue = (values) => {
  const mean = d3.mean(values);
  // d3.std returns the sample std, we need the population std here
  const std = Math.sqrt(d3.mean(values.map((x) => (x - mean) ** 2)));

  if (!std) {
    return 1;
  }

  const skew = (1 / std ** 3) * d3.mean(values.map((x) => (x - mean) ** 3));
  const kurtosis =
    (1 / std ** 4) * d3.mean(values.map((x) => (x - mean) ** 4)) - 3;

  const JB = (values.length / 6) * (skew ** 2 + kurtosis ** 2 / 4);

  return 1 - chisquare.cdf(JB, 2);
};

const computeStatistics = (values) => {
  const mode = d3.mode(values);
  return {
    count: values.length,
    pValue: computePValue(values),
    mean: d3.mean(values),
    std: d3.deviation(values),
    min: d3.min(values),
    max: d3.max(values),
    median: d3.median(values),
    mode: mode,
    modeCount: d3.sum(values.map((x) => (x === mode ? 1 : 0))),
  };
};

const cpkToColor = (cpk) => {
  if (cpk > 1.33) {
    return "darkgreen";
  }
  if (cpk > 1) {
    return "darkorange";
  }
  return "red";
};

const stdToColor = (std) => {
  if (std > 1) {
    return "red";
  }
  return "darkgreen";
};

const computePPK = (mean, std, usl, lsl) => {
  let ppk = null;

  if (usl) {
    ppk = (usl - mean) / (3 * std);
  }
  if (lsl) {
    if (ppk) {
      ppk = Math.min(ppk, (mean - lsl) / (3 * std));
    } else {
      ppk = (mean - lsl) / (3 * std);
    }
  }

  if (ppk && ppk < -99) {
    ppk = -99;
  }
  return ppk;
};

export { computeStatistics, computePPK, cpkToColor, stdToColor };
