import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  MenuItem,
  Paper,
  Box,
  Button
} from "@mui/material";
import "../App.css";
import StudentBrowserPlot from "./StudentBrowserPlot";
import Table from "./Table";
import { getData, fieldType, readFileData } from "../helper";
import { paperStyle } from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const MyBrowserStudentApp = () => {
  const [rowData, setRowData] = useState(getData());
  const [categoryField, setCategoryField] = useState("Name");
  const [dataField, setDataField] = useState("No X Data Defined");
  const [title, setTitle] = useState("Pizza Delivery");
  const [xAxisLabel, setXAxisLabel] = useState(dataField);
  const [yAxisLabel, setYAxisLabel] = useState(categoryField);
  const [categoryFields, setCategoryFields] = useState([]);
  const [dataFields, setDataFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const uploadInputRef = useRef();
  const [ClassicDesign, setClassicDesign] = useState(false);

  const handleUpload = (e) => {
    if (e.target.files.length === 0)
      return;
    const file = e.target.files[0];
    setTitle(file.name);
    setLoading(true);
    readFileData(file).then((newData) => {
      newData = newData.slice(0, 365);
      newData.columns = Object.keys(newData[0]);
      // setCategoryField(newData.columns[0]);
      // setDataField(newData.columns[1]);
      setRowData(newData);
      setLoading(false);
    });
  };
  const preventDefaultHelper = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    //const [d, c] = fieldType(rowData);
    var [d, c] = fieldType(rowData);
    setCategoryFields(c);
    c = ["No X Data Defined", ...c];
    //setDataFields(d);
    setDataFields(c);

    setXAxisLabel(c[0]);
    setYAxisLabel("__NO__CATEGORY__");
  }, [rowData]);

  useEffect(() => {
    if ((categoryFields + ["__NO__CATEGORY__"]).indexOf(categoryField) === -1) {
      setCategoryField("__NO__CATEGORY__");
    }
  }, [categoryField, categoryFields]);

  useEffect(() => {
    if (dataFields.indexOf(dataField) === -1) {
      if (dataFields.length > 0) {
        setDataField(dataFields[0]);
      }
    }
  }, [dataField, dataFields]);
  return (
    <div
      onPaste={(e) => {
        const data = e.clipboardData.getData("text/plain");
        const parsedData = d3.tsvParse(data);
        setRowData(parsedData);
        // setDataField(parsedData.columns[0]);
        // setCategoryField(parsedData.columns[1]);
      }}
      width={"100%"}
      style={{
        background: "url(background.jpg)",
        backgroundSize: "cover",
      }}
    >
      <Dialog open={loading}>
        <DialogContent>Loading</DialogContent>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper sx={paperStyle} elevation={2}>
            {/* <Typography variant="h6" sx={ClassicDesign ? numberGrayStyle : numberGreenStyle}>
              1. Provide Data
              <br /> (Max Rows = 365)
            </Typography> */}
            <Box
              component="img"
              sx={{
                height: 100,
                width: 430
              }}
              alt="Menu 1"
              src="menu1.png"
            />
            <Grid container>
              <Grid xs={6}>
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  color="error"
                  fullWidth
                  onClick={(event) => {
                    let columns = Object.keys(rowData["columns"]);
                    let data = d3.range(rowData.length).map((x) => {
                      var emptyRow = {};
                      for (let i = 0; i < columns.length; i++)
                        emptyRow[columns[i]] = "";
                      return emptyRow;
                    });
                    data.columns = rowData["columns"].map((value, index) => { return value + ' '; });
                    setRowData(data);
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  fullWidth
                  onClick={(event) => {
                    let newData = [...rowData];
                    newData.push(
                      rowData["columns"].reduce((d, f) => {
                        d[f] = "";
                        return d;
                      }, {})
                    );
                    newData.columns = rowData["columns"];
                    setRowData(newData);
                  }}
                >
                  Insert
                </Button>
              </Grid>
            </Grid>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                >
                  <ListItemIcon>
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30
                      }}
                      alt="purple"
                      src="purple.png"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Upload File" />
                  <input
                    ref={uploadInputRef}
                    id="fileInput"
                    hidden
                    accept=".xls,.xlsx,.csv,.tsv"
                    type="file"
                    onChange={handleUpload}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  contentEditable={true}
                  onPaste={preventDefaultHelper}
                  onChange={preventDefaultHelper}
                  onKeyDown={preventDefaultHelper}
                  sx={{
                    caretColor: "transparent",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30
                      }}
                      alt="purple"
                      src="purple.png"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Note: Paste data using Ctrl + V" />
                </ListItemButton>
              </ListItem>
            </List>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
              margin="normal"
              fullWidth
            />

            <Table data={rowData} setData={setRowData} />
          </Paper>
          <Paper sx={paperStyle} elevation={2}>
            {/* <Typography variant="h6" sx={ClassicDesign ? numberGrayStyle : numberGreenStyle}>
              2. Select Data and Category
            </Typography> */}
            <Box
              component="img"
              sx={{
                height: 70,
                width: 430
              }}
              alt="Menu 2"
              src="menu2.png"
            />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label={<Box
                    component="img"
                    sx={{
                      height: 30,
                      width: 100,
                    }}
                    alt="green"
                    src="green.png"
                  />}
                  id="data-field"
                  value={dataField}
                  margin="normal"
                  select
                  fullWidth
                  onChange={(e) => {
                    setDataField(e.target.value);
                    setXAxisLabel(e.target.value);
                  }}
                >
                  {dataFields.map((val) => (
                    <MenuItem key={val} value={val}>
                      <span style={{ color: val === "No X Data Defined" ? "red" : "black" }}>{val}</span>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={xAxisLabel}
                  onChange={(e) => setXAxisLabel(e.target.value)}
                  label={<b style={{ color: "blue", fontSize: "19px" }}>X Axis Label</b>}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={<Box
                    component="img"
                    sx={{
                      height: 35,
                      width: 130,
                      marginLeft: "-13px"
                    }}
                    alt="red"
                    src="red.png"
                  />}
                  id="category-field"
                  value={categoryField}
                  margin="normal"
                  select
                  fullWidth
                  onChange={(e) => {
                    setCategoryField(e.target.value)
                    setYAxisLabel(e.target.value);
                  }}
                >
                  <MenuItem value={"__NO__CATEGORY__"}>
                    <span style={{ color: "red" }}>No Category</span>
                  </MenuItem>
                  {categoryFields.map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={yAxisLabel}
                  onChange={(e) => setYAxisLabel(e.target.value)}
                  label={<b style={{ color: "green", fontSize: "19px" }}>Y Axis Label</b>}
                  margin="normal"
                />
              </Grid>

            </Grid>
          </Paper>
        </Grid>
        <Grid xs={9}>
          <StudentBrowserPlot
            rowData={rowData}
            meta={{
              title,
              dataField,
              categoryField,
              xAxisLabel,
              yAxisLabel,
            }}
            setClassicDesign={() => { setClassicDesign(!ClassicDesign) }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyBrowserStudentApp;