import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import histogram from 'highcharts/modules/histogram-bellcurve';
import { Button } from "@mui/material";
import { useState } from "react";

function calculateMean(array) {
  if (array.length === 0) {
    return 0; // return 0 if array is empty
  }
  
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    sum += array[i];
  }
  
  return sum / array.length;
}
function standardDeviation(numArray) {
  const mean = numArray.reduce((s, n) => s + n) / numArray.length;
  const variance = numArray.reduce((s, n) => s + (n - mean) ** 2, 0) / (numArray.length - 1);
  return Math.sqrt(variance);
}

export default function HistgoramChart(props) {
  
  const [LineOption,setLineOption] = useState(false);  
  var histogram_data = props.histogram_data;
  console.log(histogram_data);
  if ( histogram_data.length === 0 ) return;
  for(let i = 0 ; i < histogram_data.length ; i ++ ) {
    if(isNaN(histogram_data[i]))  return;
  }
  histogram(Highcharts);
  const average = calculateMean(histogram_data);
  const standard_derivation = standardDeviation(histogram_data);
  const options = {
    chart: {
      // backgroundColor: props.classicDesign ? 'lightgray' : '#FFF'
    },
    title: {
      text: props.meta.title,
    },
    credits: {
      enabled: false // Disables the credits watermark
    },
    subtitle: {
      //text: 'Average = ' + average.toFixed(2),
      text: `<span style="color: red;">Mean = ${average.toFixed(2)}</span><br><span style="color: blue;">Total Count = ${histogram_data.length}</span><br><span style="color: green;">StDev = ${standard_derivation.toFixed(2)}</span>`,
      style: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: 'green',
      }
    },
    xAxis: [{
      title: { text: '' },
      alignTicks: false
    }, {
      title: { text: 'Histogram' },
      alignTicks: false,
      opposite: false,
      plotLines: [{
        value: average,
        color: 'red',
        width: 3 * LineOption,
        zIndex: 10,
      }],
    }],

    yAxis: [{
      title: { text: 'Data' },
      visible: false
    }, {
      title: { text: 'Histogram' },
      opposite: false
    }],

    plotOptions: {
      histogram: {
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
          }
        }
      }
    },

    series: [{
      name: 'Histogram',
      type: 'histogram',
      xAxis: 1,
      yAxis: 1,
      baseSeries: 's1',
      zIndex: -1
    }, {
      name: 'Data',
      type: 'scatter',
      data: histogram_data,
      id: 's1',
      marker: {
        radius: 1.5
      },
      visible: false
    }],
    tooltip: {
      formatter: function() {
        var nextPoint = this.series.data[this.point.index + 1];
        var nextXValue = nextPoint ? nextPoint.x : null;
        if ( nextXValue !== null )
          return this.x.toFixed(3) + ' - ' + nextXValue.toFixed(3) + '<br/>' + 'Frequency: ' + this.y;
        else
          return this.x.toFixed(3) + ' - ' + Math.max(...histogram_data) + '<br/>' + 'Frequency: ' + this.y;
      }
    }
  }
  return (
    <div>      
      <Button variant="contained" color="error" style={{ marginLeft: "40px" }} onClick={(event) => {
        setLineOption(!LineOption);
      }}>Mean Line</Button>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}